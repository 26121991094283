import React from 'react';
import Flex from 'Components/Grid/Flex';
import Button from 'Components/ThemedButton';
import styled, { css, keyframes } from 'styled-components';
import { colors } from 'constants/colors';
import { mediaSmDown } from 'theme/helpers/css';
import { useDispatch } from 'react-redux';
import CartActions from 'Modules/Cart/actions';
import { EnumTheme } from '../../../../constants/enums';

const shake = keyframes`
  0% {
    transform: translate(3px, 0px) rotate(0deg);
  }
  10% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(0deg);
  }
  30% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  40% {
    transform: translate(3px, 0px) rotate(0deg);
  }
  50% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  60% {
    transform: translate(-3px, 0px) rotate(0deg);
  }
  70% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  80% {
    transform: translate(3px, 0px) rotate(0deg);
  }
  90% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    transform: translate(-3px, 0px) rotate(0deg);
  }
`;

export const containerAnimation = css`
  animation: 1.2s linear;
  animation-name: ${shake};
`;

const Container = styled(Flex).attrs({
  mb: { xs: 6.5, sm: 8 },
})<{
  isAnimationNeeded: boolean;
}>`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px 16px;
  gap: 28px;
  max-width: 382px;
  width: 100%;
  height: 58px;
  border-radius: 12px;
  background-color: ${colors.ghostWhite};
  font-size: 14px;
  color: ${colors.blackRussian};
  ${({ isAnimationNeeded }) => isAnimationNeeded && containerAnimation};
  ${mediaSmDown`
    max-width: 335px;
    width: 100%;
    height: 64px;
    gap: 18px;
   `}
`;

const SectionText = styled.span`
  max-width: 203px;
  width: 100%;
  color: ${colors.dark300};
  font-size: 16px;

  ${mediaSmDown`
    max-width: 168px;
    width: 100%;
   `}
`;

const DeleteSoldOutButton = styled(Button)`
  height: 34px;
  padding: 9px 18px;
  font-size: 14px;
  border-radius: 30px;
`;

interface ISoldOutDelete {
  isAnimationNeeded: boolean;
}

const SoldOutProductsDelete: React.FC<ISoldOutDelete> = ({ isAnimationNeeded }: ISoldOutDelete) => {
  const dispatch = useDispatch();

  const handleDeleteUnavailableProducts = async () => {
    dispatch(CartActions.removeUnavailableProducts());
  };

  return (
    <Container id="unavailableDeleteContainer" isAnimationNeeded={isAnimationNeeded}>
      <SectionText>Часть товаров раскупили</SectionText>
      <DeleteSoldOutButton use={EnumTheme.roundRed} onClick={handleDeleteUnavailableProducts}>
        Удалить их
      </DeleteSoldOutButton>
    </Container>
  );
};

export default SoldOutProductsDelete;
