import React from 'react';
import Icon, { IIcon } from './Icon';

const BadgeHotIcon: React.FC<IIcon> = props => (
  <Icon width="22" height="22" viewBox="0 0 22 22" color="#FFBC6E" {...props}>
    <g clipPath="url(#clip0_1_48)">
      <circle cx="11" cy="11" r="10.5" stroke="white" />
      <path
        d="M10.2133 9.94977C10.4435 7.96222 11.9412 6.667 12.8411 6.16015C12.9228 6.11411 13.0225 6.19206 13.0185 6.28579C12.983 7.10932 13.5788 8.64885 14.0661 9.82083C14.3356 10.4691 14.6467 11.7704 14.0827 13.0648C13.5941 14.1861 12.7135 14.8685 11.717 15.2817C10.8627 15.636 8.31971 15.5739 7.28563 12.9281C6.5537 11.0554 7.59079 9.16669 8.92832 7.97129C9.00765 7.90039 9.12941 7.97724 9.10959 8.08178C8.93749 8.98989 9.12888 9.7346 10.0739 10.0434C10.139 10.0646 10.2054 10.0177 10.2133 9.94977Z"
        fill="currentColor"
        stroke="white"
        strokeWidth="1.22222"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_48">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

export default BadgeHotIcon;
