import React from 'react';
import { colors } from 'constants/colors';
import styled from 'styled-components';
import ArrowGeo from 'Components/Icon/ArrowGeo';
import { Box, Flex } from '@igooods/ui-kit';
import SearchInput from './SearchInput';
import { FontWeight, Transitions } from 'theme';
import { mediaCustomUp, mediaDesktop, mediaMobile } from 'theme/helpers/css';
import { Title } from 'Components/Typography';
import ThemeButton from 'Components/ThemedButton';

export const Form = styled.form`
  display: flex;
  margin: 0;
  ${mediaMobile`
    margin-left: 0;
    flex-flow: column;
    width: 100%;
    margin-top: 0;
  `}
`;

export const FormTitle = styled(Title).attrs({
  titleLevel: '1',
  level: '1',
  ...{
    color: colors.dark300,
  },
})`
  margin-bottom: 34px;
  font-weight: ${FontWeight.semiBold};

  ${mediaCustomUp(1920)`
    font-size: 38px;
    line-height: 46px;
  `}

  ${mediaMobile`
    margin-bottom: 0;
  `}
`;

export const LocationBtn = styled(Flex).attrs({
  middle: true,
  center: true,
  children: <ArrowGeo color={colors.dark200} />,
})`
  ${mediaMobile`
    display: none;
  `}
  width: 48px;
  height: 48px;
  cursor: pointer;
  border-radius: 100px;
  &:hover {
    background-color: ${colors.ghostWhiteTwo};
  }
`;

export const LocationBtnLanding = styled(LocationBtn)`
  margin-left: -11px;
`;

export const MapBtn = styled(Flex).attrs({ px: 3.5, center: true, middle: true })`
  border-radius: 100px;
  height: 48px;
  cursor: pointer;
  transition: ${Transitions.bg};
  &:hover {
    background-color: ${colors.ghostWhiteTwo};
  }
`;

export const SearchAddressButton = styled(ThemeButton)`
  ${mediaDesktop`
    width: auto;
    padding: 0 30px;
  `}
`;

export const StyledAddressInput = styled(SearchInput)`
  background-color: ${colors.light200};
  color: ${colors.primaryMain};
  ${mediaDesktop`
    width: 520px;
  `}
`;

export const InputContainer = styled(Box).attrs({ mr: { sm: 3.75, xs: 0 }, mtXs: 5.5 })`
  position: relative;
  ${mediaDesktop`
    width: 520px;
  `}
`;

export const SubmitBtnContainer = styled(Box).attrs({ mtXs: 3.4 })<{ $width: string }>`
  ${mediaDesktop`
    width: ${({ $width }: { $width: string }) => $width}px;
  `}
  ${mediaMobile`
    width: auto;
  `}
`;

export const TitleWrap = styled(Flex)`
  ${mediaDesktop`
    width: auto;
  `}
  ${mediaMobile`
    width: 100%;
  `}
`;
