import React, { FC, useContext, memo } from 'react';
import styled from 'styled-components';
import ProductsLoading from '../../../Loading/ProductsLoading';
import Slider, { SliderWrapper, StyledDots, EnumArrowType } from 'Components/Slider';
import { IProductsResponse } from 'Modules/Products/types';
import { EnumFromName } from 'constants/enums';
import ProductCol from 'Components/Products/ProductCol';
import ProductsContext from 'Components/Products/context';
import Title from 'Components/Typography/Title';
import { FontSize, FontFamily } from 'theme';
import { getTypographyFontStyle } from 'theme/selectors';
import { mediaSmUp, mediaMdUp } from 'theme/helpers/css';
import { useRequest } from 'swr.config';
import { Box } from '@igooods/ui-kit';
import { useServerData } from 'serverDataContext';
import { useSelector } from 'react-redux';
import { getCurrentShopId } from 'Modules/Shops/selectors';
import CategoryProduct from 'Containers/Category/CategoryProduct';
import ProductsListContainer from 'Components/Products/ProductsListContainer';

export const SSR_KEY = 'IMPULSE_GOODS';
export const REQUEST_LIMIT = 10;

export const IMPULSE_GOODS_LOADING_TEST_ID = 'IMPULSE_GOODS_LOADING_TEST_ID';
export const IMPULSE_GOODS_COL_TEST_ID = 'IMPULSE_GOODS_COL_TEST_ID';
export const IMPULSE_GOODS_SLIDER_TEST_ID = 'IMPULSE_GOODS_SLIDER_TEST_ID';

const StyledSlider = styled(Slider).attrs({
  withDots: true,
  infinite: true,
  withArrows: true,
  arrowType: EnumArrowType.topRound,
})`
  width: 100%;
  height: 420px;

  ${SliderWrapper} {
    overflow: visible;
  }

  ${StyledDots} {
    width: max-content;
    margin: auto;
  }

  ${mediaSmUp`
    ${StyledDots} { 
    }
  `}

  ${mediaMdUp`
    ${SliderWrapper} {
      height: 420px;
      overflow: hidden;
    }

    ${StyledDots} {
      display: none;
    }
  `}
`;

const ContentTitle = styled(Title).attrs({ fontFamily: FontFamily.secondFont })`
  ${getTypographyFontStyle(FontSize.l)}

  ${mediaMdUp`
    width: 100%;
    ${getTypographyFontStyle(FontSize.xl)}
  `}
`;

const StyledProductCol = styled(ProductCol)`
  width: 100%;
`;

const ImpulseGoods: FC = () => {
  const { analyticsIntersectionObserver } = useContext(ProductsContext);
  const shop_id = useSelector(getCurrentShopId);
  const serverData = useServerData(SSR_KEY, [shop_id]);
  const { data } = useRequest<IProductsResponse>(['product_sets/checkout', { limit: REQUEST_LIMIT, shop_id }], {
    initialData: serverData,
  });

  return (
    <Box mt={{ xs: 8.5, md: 20 }}>
      <ContentTitle level="1">Что-то еще?</ContentTitle>
      <ProductsListContainer mt={{ xs: 4, sm: 5, md: 7.5 }} mb={{ xs: 0, lg: 0 }}>
        {data?.products ? (
          <StyledSlider testId={IMPULSE_GOODS_SLIDER_TEST_ID}>
            {data.products.map((product, index) => (
              <StyledProductCol key={product.id} data-testid={IMPULSE_GOODS_COL_TEST_ID}>
                <CategoryProduct
                  position={index + 1}
                  product={product}
                  from={EnumFromName.IMPULSE}
                  analyticsIntersectionObserver={analyticsIntersectionObserver}
                  withoutAddToUserSet
                />
              </StyledProductCol>
            ))}
          </StyledSlider>
        ) : (
          <ProductsLoading />
        )}
      </ProductsListContainer>
    </Box>
  );
};

export default memo(ImpulseGoods);
