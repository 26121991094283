import styled from 'styled-components';
import { colors } from 'constants/colors';
import Title from 'Components/Typography/Title';
import Text from 'Components/Typography/Text';
import { mediaSmDown, mediaMdDown, mediaLgDown } from 'theme/helpers/css';
import ThemeButton from 'Components/ThemedButton';
import Flex from 'Components/Grid/Flex';
import { Box } from '@igooods/ui-kit';
import { CheckContainer } from 'Components/Check/index';
import { DefaultTheme } from 'styled-components';
import { EnumTheme, EnumButtonSize } from 'constants/enums';
import { getTypographyFontSize } from 'theme/selectors';
import { FontFamily, FontSize, FontWeight } from 'theme';
import ColoredLinkWrapper from 'Components/Link/ColoredLinkWrapper';
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE, HEADER_HEIGHT_TABLET } from 'Components/Header/HeaderContainer';

export const CartOrderDescription = styled.div<{ theme: DefaultTheme }>`
  ${({ theme }) => `
    @media (max-height: 770px) and (min-width: ${theme.breakpoints.md}px) {
      position: relative;

      ${CheckContainer} {
        position: absolute;
        top: 35px;
      }
    }`}
`;

export const CartHeaderContent = styled(Flex).attrs({
  px: { xs: 4, sm: 13, md: 9 },
  pt: { xs: 8.5, sm: 13, md: 9.5 },
  pb: { xs: 2.75, sm: 7, md: 0 },
  column: true,
  between: true,
})`
  position: relative;

  ${mediaMdDown`
    flex-direction: row;
    align-items: flex-end;
  `}
`;

export const CartEmptyHeader = styled(CartHeaderContent)`
  text-align: center;

  ${mediaSmDown`
    text-align: left;
    padding: 16px 16px 24px;
  `}
`;

export const CartTitle = styled(Title)`
  font-weight: ${FontWeight.semiBold};
  margin-left: 8px;
`;

export const LinkCart = styled(ColoredLinkWrapper).attrs({ color: colors.primaryMain })`
  ${getTypographyFontSize(FontSize.s)};
`;

export const ClearButton = styled(ThemeButton).attrs({
  use: EnumTheme.textSecondaryMain,
  size: EnumButtonSize.small,
})`
  height: auto;
  font-weight: ${FontWeight.semiBold};
  font-family: ${FontFamily.secondFont};
  padding: 0;

  ${mediaLgDown`
    margin-top: 10px;
  `}
`;

export const CartCategoryContainer = styled.div`
  margin-top: 40px;
`;

export const CartCategoryTitle = styled(Title).attrs({
  level: '2',
})`
  margin-bottom: 20px;

  span {
    color: ${colors.pinkishGrey};
    margin-left: 12px;
    font-size: 18px;
  }

  ${mediaSmDown`
    margin-bottom: 16px;
  `}
`;

export const CartTitleLoading = styled.div`
  width: 320px;
  height: 40px;
  background-color: ${colors.paleGrey};
`;

export const CartSubTitleLoading = styled.div`
  margin-top: 10px;
  width: 150px;
  height: 20px;
  background-color: ${colors.paleGrey};
`;

export const CartSubTitle = styled(Flex).attrs({ middle: true })`
  ${getTypographyFontSize(FontSize.s)};

  ${mediaSmDown`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const Description = styled(Text)`
  margin-right: 5px;

  ${mediaSmDown`
    color: ${colors.warmGrey}
  `}
`;

export const CartContent = styled(Box).attrs({
  px: { xs: 5, sm: 13, md: 9 },
  pb: { xs: 4, md: 0 },
  pt: { xs: 0, sm: 7.5 },
  mb: { md: 25 },
})`
  width: calc(100% - 276px);

  ${mediaMdDown`
    width: 100%;
  `}
`;

export const CartEmptyContent = styled(CartContent)`
  width: 100%;
`;

export const MobileCartFooter = styled(Box).attrs({ hideAfter: 'sm', py: 3, px: 5 })`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: ${colors.white};
  box-sizing: border-box;
  backdrop-filter: blur(43.4925px);
`;

export const CartContainer = styled(Flex).attrs({ column: true })`
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  ${mediaMdDown`
    min-height: calc(100vh - ${HEADER_HEIGHT_TABLET}px);
  `}
  ${mediaSmDown`
    min-height: calc(100vh - ${HEADER_HEIGHT_MOBILE}px);
  `}
`;

export const SubmitMobileButton = styled(ThemeButton).attrs({
  size: EnumButtonSize.large,
  fluid: true,
  use: EnumTheme.roundRed,
})<{
  isHaveSoldoutProducts?: boolean;
}>`
  line-height: 18px;
  ${({ isHaveSoldoutProducts }) =>
    isHaveSoldoutProducts &&
    `border: none !important; opacity: 0.8 !important; background-color: ${colors.paleGreen2} !important; color: ${colors.white} !important`}
  &:hover,
  &:focus {
    button {
      border: none !important;
    }
  }
  &:disabled {
    background-color: ${colors.paleGrey};
    color: ${colors.pinkishGrey};
  }
`;

export const NotificationWrapper = styled(Flex)`
  flex-direction: row;
  ${mediaSmDown`
    flex-direction: column;
  `}
`;
