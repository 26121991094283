import React from 'react';
import { useHistory } from 'react-router';
import { ProductLink } from './styled';
import { URL } from 'constants/urlMaps';
import { IProduct } from 'Modules/Products/types';
import { setProductToLocation, IProductModalOptions } from 'Containers/Modals/ProductByUrl/utils';

interface ILinkWrapperProps {
  product: IProduct;
  options?: IProductModalOptions;
}

const LinkWrapper: React.FC<ILinkWrapperProps> = ({ product, children, options }) => {
  const history = useHistory();
  const { model_id, parameterize } = product;
  const productUrl = `${URL.product_params}/${model_id}-${parameterize}`;

  const handleOpenProductModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const hasKeyModifiers = e.ctrlKey || e.metaKey; // проверяем на нажатый ctrl или cmd
    if (!hasKeyModifiers) {
      e.preventDefault();
      setProductToLocation(history, model_id, { ...options, product });
    }
  };

  return (
    <ProductLink to={productUrl} onClick={handleOpenProductModal}>
      {children}
    </ProductLink>
  );
};

export default LinkWrapper;
