import React, { useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import SortingTab, { ITab } from './SortingTab';
import { mediaSmDown } from 'theme/helpers/css';
import Flex from 'Components/Grid/Flex';
import SortIcon from '../Icon/SortIcon';
import { colors } from 'constants/colors';

export interface ISortingTabs {
  tabs: ITab[];
  currentChip?: string;
  defaultTab?: number;
  isCheap?: boolean;
}

const Tabs = styled(Flex)`
  border-bottom: 1px solid ${colors.paleGreyThree};
  ${mediaSmDown`
    flex-wrap: nowrap;
  `}
`;

const SortingTabs: React.FC<ISortingTabs> = ({ tabs, defaultTab = 0, isCheap, currentChip }) => {
  const [activeTab, setActiveTab] = useState(defaultTab);

  useLayoutEffect(() => {
    setActiveTab(defaultTab);
  }, [currentChip, defaultTab]);

  const tabContent = tabs.map((filter: ITab, index: number) => (
    <SortingTab
      key={filter.type}
      active={activeTab === index}
      data-testid={`filter-sort-${filter.type}`}
      onClick={() => setActiveTab(index)}
      isCheap={isCheap}
      {...filter}
    >
      {filter.title}
      {filter.hasDirection && <SortIcon />}
    </SortingTab>
  ));

  return isCheap ? (
    <>{tabContent}</>
  ) : (
    <Tabs grow wrap>
      {tabContent}
    </Tabs>
  );
};

export default SortingTabs;
