import React, { FC } from 'react';
import styled from 'styled-components';
import { mediaSmDown, mediaSmUp } from 'theme/helpers/css';
import { Box } from '@igooods/ui-kit';
import { FontSize } from 'theme';
import { getTypographyFontStyle } from 'theme/selectors';
import clockImg from 'assets/images/clock.png';
import { SATitle } from 'Containers/SelectAddress/Search/Desktop';
import { Spring, animated } from 'react-spring';

export const NOT_FOUND_SHOPS_SUCCESS_SEND_EMAIL_TEST_ID = 'NOT_FOUND_SHOPS_SUCCESS_SEND_EMAIL_TEST_ID';

const Container = styled(animated.div)`
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1 1 auto;
  margin: -60px auto 0;
  max-width: 510px;

  ${mediaSmDown`
    margin: 0 auto;
  `}
`;

const Subtitle = styled(Box).attrs({
  mt: { xs: 2, sm: 5 },
})`
  text-align: center;
  ${getTypographyFontStyle(FontSize.m)}

  ${mediaSmUp`
    max-width: 600px;
  `}
`;

export const ImageClock = styled.div`
  width: 138px;
  height: 180px;
  background-size: contain;
  background-image: url(${clockImg});
  margin: 0 auto 40px;
`;

const SuccessedEmail: FC = () => {
  return (
    <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
      {styles => (
        <Container style={styles} data-testid={NOT_FOUND_SHOPS_SUCCESS_SEND_EMAIL_TEST_ID}>
          <ImageClock />
          <SATitle center>Сохранили вашу почту</SATitle>
          <Subtitle>Обязательно напишем, когда начнем доставлять по&nbsp;вашему адресу.</Subtitle>
        </Container>
      )}
    </Spring>
  );
};

export default SuccessedEmail;
