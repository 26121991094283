import React, { FC, useEffect, useRef } from 'react';
import { EnumButtonSize, EnumTheme } from 'constants/enums';
import { colors } from 'constants/colors';
import CloseButtonIcon from 'Components/Icon/CloseButtonIcon';
import { useOnClickOutside } from 'utils/hooks';
import { Block, CloseBtn, Content, ContentWrapper, DropdownContent } from './styles';

export const HEADER_DROPDOWN_CONTAINER_TEST_ID = 'HEADER_DROPDOWN_CONTAINER_TEST_ID';
export const HEADER_DROPDOWN_CONTENT_TEST_ID = 'HEADER_DROPDOWN_CONTENT_TEST_ID';
export const HEADER_DROPDOWN_CLOSE_BTN_TEST_ID = 'HEADER_DROPDOWN_CLOSE_BTN_TEST_ID';
export const HEADER_DROPDOWN_TOGGLE_TEST_ID = 'HEADER_DROPDOWN_TOGGLE_TEST_ID';

interface IHeaderDropdown {
  isOpen?: boolean;
  children: React.ReactNode | React.ReactChild;
  className?: string;
  toggle?: React.ReactChild | React.ReactNode;
  onHide: () => void;
  onToggle?: () => void;
  top?: string;
  fullHeight?: boolean;
  testId?: string;
  withCloseBtn?: boolean;
  withHideOnClickOutside?: boolean;
}

const HeaderDropdown: FC<IHeaderDropdown> = ({
  className,
  toggle,
  children,
  onHide,
  onToggle,
  top = '100%',
  isOpen = false,
  fullHeight = false,
  testId = HEADER_DROPDOWN_CONTAINER_TEST_ID,
  withCloseBtn = false,
  withHideOnClickOutside = true,
}) => {
  const containerNode = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closeOnEsc = (e: KeyboardEvent) => e.key === 'Escape' && onHide && onHide();

    const startListenEsc = () => document.addEventListener('keydown', closeOnEsc, false);
    const stopListenEsc = () => document.removeEventListener('keydown', closeOnEsc, false);

    if (isOpen && onHide) {
      startListenEsc();
    } else {
      stopListenEsc();
    }
    return stopListenEsc;
  }, [isOpen, onHide]);

  useOnClickOutside(containerNode, onHide, withHideOnClickOutside);

  return (
    <div className={className} data-testid={testId} ref={containerNode}>
      {toggle && (
        <div data-testid={HEADER_DROPDOWN_TOGGLE_TEST_ID} onClick={onToggle}>
          {toggle}
        </div>
      )}
      <DropdownContent
        data-testid={HEADER_DROPDOWN_CONTENT_TEST_ID}
        $isOpen={isOpen}
        $top={top}
        $fullHeight={fullHeight}
      >
        <Block>
          <ContentWrapper>
            {withCloseBtn && onHide && (
              <CloseBtn
                data-testid={HEADER_DROPDOWN_CLOSE_BTN_TEST_ID}
                onClick={onHide}
                use={EnumTheme.roundRed}
                size={EnumButtonSize.normal}
                icon
              >
                <CloseButtonIcon color={colors.white} />
              </CloseBtn>
            )}
            <Content>{children}</Content>
          </ContentWrapper>
        </Block>
      </DropdownContent>
    </div>
  );
};

export default HeaderDropdown;
