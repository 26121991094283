/* eslint-disable */
import React from 'react';
import Icon, { IIcon } from './Icon';

const TrashIcon: React.FC<IIcon> = ({ ...props }) => (
  <Icon width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6815 16.5971C15.4927 17.5298 14.6729 18.2003 13.7213 18.2003H8.49283C7.54118 18.2003 6.72133 17.5298 6.53257 16.5971L4.42847 6.2002L17.7856 6.20032L15.6815 16.5971Z" fill="white" stroke="#B5B5BB" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.143 6.2002H8.07153L8.51644 4.00184C8.61082 3.53547 9.02075 3.2002 9.49657 3.2002H12.7179C13.1937 3.2002 13.6037 3.53547 13.6981 4.00184L14.143 6.2002Z" fill="white" stroke="#B5B5BB" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.21436 6.19971H19.0001" stroke="#B5B5BB" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.6499 13.9844L13.6985 9.90019" stroke="#B5B5BB" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.6499 13.9844L8.60132 9.90019" stroke="#B5B5BB" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
  </Icon>
);

export default TrashIcon;
