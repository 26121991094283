import React, { FC } from 'react';
import Icon, { IIcon } from './Icon';

const SortIcon: FC<IIcon> = props => (
  <Icon width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="nonzero"
      fill={props.color || 'currentColor'}
      d="M1 9h10v1.15H1V9zm0-4h7v1.15H1V5zm0-4h4v1.15H1V1z"
    />
  </Icon>
);

export default SortIcon;
