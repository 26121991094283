import React, { FC, ReactElement, RefObject, useEffect, useState } from 'react';
import styled from 'styled-components';
import Dot from './Dot';
import Flickity from 'react-flickity-component';
import { mediaSmDown } from 'theme/helpers/css';
import { loaderLineAnim } from 'Containers/Profile/ProfileComponents/skeletons';
import { colors } from 'constants/colors';
import { useServer } from 'utils/hooks/useServer';

export const DotsWrapper = styled.div`
  margin-top: 24px;
  ${mediaSmDown`
    margin-left: 16px;
  `}
`;

const LoadingDots = styled(DotsWrapper)`
  width: 100px;
  height: 24px;
  border-radius: 12px;
  ${loaderLineAnim};
  background-color: ${colors.silver};
`;

interface IDotsProps {
  flickRef: RefObject<Flickity>;
  className?: string;
}

const Dots: FC<IDotsProps> = ({ flickRef, className }) => {
  const [slidesCount, setSlidesCount] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  const [paused, setIsPaused] = useState(false);
  const isServer = useServer();

  useEffect(() => {
    if (flickRef.current) {
      flickRef.current.on('change', (idx: number) => {
        setActiveSlide(idx);
      });
      flickRef.current.on('ready', () => {
        const slides = flickRef.current?.slides as ReactElement[];
        setSlidesCount(slides.length);
        setActiveSlide(0);
      });
      flickRef.current.on('resize', () => {
        const slides = flickRef.current?.slides as ReactElement[];
        setSlidesCount(slides.length);
      });
      flickRef.current.on('pausePlayer', () => setIsPaused(true));
      flickRef.current.on('unpausePlayer', () => setIsPaused(false));
    }
  }, [flickRef]);

  const handleDotClick = (index: number) => () => {
    flickRef.current?.select(index);
  };
  const renderDots = () => {
    const dotsArr = [];

    if (flickRef.current) {
      for (let i = 0; i < slidesCount || 0; i++) {
        dotsArr.push(<Dot key={i} onClick={handleDotClick(i)} active={i === activeSlide} paused={paused} />);
      }
    }

    return dotsArr;
  };
  if (isServer) {
    return <LoadingDots className={className} />;
  }

  return slidesCount > 1 ? <DotsWrapper className={className}>{renderDots()}</DotsWrapper> : null;
};

export default Dots;
