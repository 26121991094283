import React, { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { addStyleIfPropTruly } from 'utils/styledComponents';
import { AUTOPLAY_DURATION } from './contants';

const PROGRESS_CIRCLE_RADIUS = 10;
const PROGRESS_CIRCLE_LENGTH = (2 * Math.PI * PROGRESS_CIRCLE_RADIUS).toFixed(3);

const circleProgress = keyframes`
  from {
    stroke-dashoffset: ${PROGRESS_CIRCLE_LENGTH};
  }
  to {
    stroke-dashoffset: 0;
  }
`;

export const ProgressCircle = styled.circle<{ $active: boolean; $paused?: boolean }>`
  stroke-width: 3px;
  stroke: #004b46;
  stroke-opacity: 0.4;
  stroke-dasharray: ${PROGRESS_CIRCLE_LENGTH};
  stroke-dashoffset: ${PROGRESS_CIRCLE_LENGTH};
  transform-origin: center center;
  transform: rotateZ(51deg) rotateX(180deg);
  ${addStyleIfPropTruly(
    '$active',
    css`
      animation: ${circleProgress} ${AUTOPLAY_DURATION / 1000}s linear forwards;
    `
  )}
  ${addStyleIfPropTruly('$paused', 'animation-play-state: paused;')}
`;

const DotButton = styled.button`
  background-color: transparent;
  padding: 0;
  cursor: pointer;
`;

const Dot: FC<{
  className?: string;
  active: boolean;
  paused?: boolean;
  onClick: () => void;
}> = ({ active, paused, onClick }) => (
  <DotButton onClick={onClick}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ProgressCircle cx="12" cy="12" r={PROGRESS_CIRCLE_RADIUS} $active={active} $paused={paused} />
      <circle cx="12" cy="12" r="4" fill="#004B46" />
    </svg>
  </DotButton>
);

export default Dot;
