import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkIsTempUser } from 'Modules/AAA/selectors';
import ModalActions from 'Modules/Modals/actions';
import { EnumFromName, ModalType, ProductFavoriteStates } from 'constants/enums';
import { useDebounceCallback } from 'utils/hooks';
import ProductActions from '../../Modules/Products/actions';
import { IProduct } from 'Modules/Products/types';
import { setNotificationAction } from 'Modules/Notifications/actions';
import { getIsFavoritesIdle, getIsProductInFavoritesList } from 'Modules/Favorites/selectors';

interface IUseFavoriteHandlerOptions {
  from: EnumFromName;
  from_product_page?: boolean;
  onOpenAuth?: () => void;
}

const ERR_MSG = 'Не удалось добавить товар в «избранные»';

const useFavoriteHandler = (
  product: IProduct,
  { from, from_product_page = false, onOpenAuth }: IUseFavoriteHandlerOptions,
  currentFavoriteId?: string
) => {
  const [isFavoriteLocal, setIsFavoriteLocal] = useState(product.favorite);
  const isTempUser = useSelector(checkIsTempUser);
  const favoriteState = isTempUser ? ProductFavoriteStates.unlogged : ProductFavoriteStates.default;
  const dispatch = useDispatch();
  const openAuthModal = () => dispatch(ModalActions.openModal(ModalType.Auth));
  const isFavoritesIdle = useSelector(getIsFavoritesIdle);
  const isInFavoritesList = useSelector(getIsProductInFavoritesList(product.id));

  useEffect(() => {
    setIsFavoriteLocal(product.favorite);
    if (!isFavoritesIdle) {
      setIsFavoriteLocal(isInFavoritesList);
    }
  }, [isFavoritesIdle, isInFavoritesList, product.favorite]);

  const toggleFavorite = useDebounceCallback(async (toFavorites: boolean) => {
    setIsFavoriteLocal(toFavorites);

    try {
      await dispatch(
        ProductActions.toggleProductToFavorites(
          {
            model_id: product.model_id,
            favorite: toFavorites,
          },
          product,
          from,
          from_product_page,
          currentFavoriteId
        )
      );
    } catch (e) {
      setIsFavoriteLocal(!toFavorites);
      dispatch(setNotificationAction(ERR_MSG));
      console.error(e);
    }
  }, 500);

  const handleFavoriteClick = () => {
    if (isTempUser) {
      onOpenAuth && onOpenAuth();
      openAuthModal();
    } else toggleFavorite(!isFavoriteLocal);
  };

  return { favoriteState, isFavoriteLocal, handleFavoriteClick };
};

export default useFavoriteHandler;
