import React, { useCallback } from 'react';
import { sortStates } from 'constants/enums';
import SortingTabs from 'Components/SortingTabs/index';
import Box from 'Components/Grid/Box';
import styled from 'styled-components';
import { mediaSmDown } from 'theme/helpers/css';

export const PRODUCT_FILTER_SORT_SEARCH_TEST_ID = 'PRODUCT_FILTER_SORT_SEARCH_TEST_ID';

const Container = styled(Box).attrs({
  mt: { xs: 6, sm: 1.5 },
  mb: { xs: 7, sm: 9 },
})``;

const Tabs = styled(Box)`
  margin-left: 0;

  ${mediaSmDown`
    margin: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 36px;
  `}
`;

interface ISortFilters {
  setSortState: (state: any) => void;
  setDirectSort: (state: any) => void;
}

const FiltersSort: React.FC<ISortFilters> = ({ setSortState, setDirectSort }: ISortFilters) => {
  const handleSortByAdding = useCallback(
    (type, direction) => {
      setDirectSort(direction);
      setSortState(type);
    },
    [setDirectSort, setSortState]
  );

  const sortFilters = [
    {
      type: sortStates.byAdding,
      title: 'По добавлению',
      onSort: handleSortByAdding,
      hasDirection: true,
    },
    {
      type: sortStates.byCategory,
      title: 'По категориям',
      onSort: handleSortByAdding,
    },
  ];

  return (
    <Container>
      <Tabs mx={3} width="100%">
        <SortingTabs tabs={sortFilters} defaultTab={0} isCheap />
      </Tabs>
    </Container>
  );
};

export default FiltersSort;
