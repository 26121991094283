import { EMPTY_ARRAY, EMPTY_STRING } from 'constants/constants';
import { IShopsForPlaceResponse } from '../../Modules/Shops/http';

export enum BreakPointsCards {
  big = 280,
  middle = 170,
  small = 130,
}

export enum ModeCards {
  big = 'lg',
  middle = 'md',
  small = 'sm',
  smallest = 'xs',
}

export enum EnumSelectShopFrom {
  SA = 'SA',
  Main = 'Main',
  Catalog = 'Catalog',
}

export enum statesSS {
  loading = 'loading_shops',
  empty = 'empty_shops',
  success = 'success_shops',
}

export const initDataShopsForPlace: IShopsForPlaceResponse = {
  place: {
    name: EMPTY_STRING,
    coordinates: { latitude: 0, longitude: 0 },
    regional_center_id: '1',
    address_components: [],
  },
  list: EMPTY_ARRAY,
};

export const SELECT_SHOP_LOADING_CONTAINER_TEST_ID = 'SELECT_SHOP_LOADING_CONTAINER_TEST_ID';
export const SELECT_SHOP_CARD_LOADING_TEST_ID = 'SELECT_SHOP_CARD_LOADING_TEST_ID';
