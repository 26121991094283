import React from 'react';
import Icon, { IIcon } from './Icon';

const IncreaseIcon: React.FC<IIcon> = ({ color, ...props }) => (
  <Icon width="22" height="22" viewBox="0 0 22 22" {...props} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.16777 16.8267C9.07203 17.2863 9.36697 17.7365 9.82654 17.8322C10.2861 17.928 10.7363 17.633 10.832 17.1734L11.9411 11.8501H17.4999C17.9693 11.8501 18.3499 11.4695 18.3499 11.0001C18.3499 10.5306 17.9693 10.1501 17.4999 10.1501H12.2952L13.332 5.17343C13.4278 4.71386 13.1328 4.26368 12.6733 4.16794C12.2137 4.0722 11.7635 4.36714 11.6678 4.82671L10.5587 10.1501H4.9999C4.53046 10.1501 4.1499 10.5306 4.1499 11.0001C4.1499 11.4695 4.53046 11.8501 4.9999 11.8501H10.2046L9.16777 16.8267Z"
      fill={color}
    />
  </Icon>
);

export default IncreaseIcon;
