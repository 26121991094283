import React from 'react';
import Icon, { IIcon } from './Icon';

const PencilIcon: React.FC<IIcon> = props => (
  <Icon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.86686 13.9054L3.36377 14.7273L4.18564 11.2247L12.6367 2.77194C13.0604 2.34881 13.7468 2.34881 14.1699 2.77194L15.3196 3.92104C15.7421 4.34417 15.7421 5.03006 15.3196 5.45373L6.86686 13.9054Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1818 6.54554L11.5454 4.90918"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default PencilIcon;
