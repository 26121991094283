import React from 'react';
import BadgeHotIcon from 'Components/Icon/BadgeHotIcon';
import BadgeNewIcon from 'Components/Icon/BadgeNewIcon';
import BadgeApprovedIcon from 'Components/Icon/BadgeApprovedIcon';
import { EnumLabels } from 'Modules/Products/types';
import { Grid } from '@igooods/ui-kit';
import styled from 'styled-components';

const Container = styled(Grid).attrs({
  gapCol: 1.5,
  autoCol: 'auto',
})`
  grid-auto-flow: column;
`;

const Badges = ({ labels }: { labels?: EnumLabels[] }) => (
  <Container>
    {
      /* С бэка приходит массив с леблами всех рекламных кампаний */
      /* Первый элемент - самый актуальный лебл, пока что просто выводим его */
      labels?.length
        ? {
            [EnumLabels.HIT]: <BadgeHotIcon />,
            [EnumLabels.NOVELTY]: <BadgeNewIcon />,
            [EnumLabels.PROMO]: <BadgeApprovedIcon />,
          }[labels[0]]
        : null
    }
  </Container>
);

export default Badges;
