export const SELECT_SHOP_BUTTON_HEIGHT = 40;

export const SINGLE_SHOP_HEIGHT = 104;

export const SHOP_LIST_PADDING_BOTTOM = 20;

export const CHANGE_SHOP_BTN_HEIGHT = 48;

export const ANALYTICS_CHARS_LIMIT = 36;

export const CHANGE_SHOP_BTN_TEST_ID = 'CHANGE_SHOP_BTN_TEST_ID';
export const CHANGE_SHOP_DROPDOWN_TEST_ID = 'CHANGE_SHOP_DROPDOWN_TEST_ID';
export const CHANGE_SHOP_ITEM_CURRENT_TEST_ID = 'CHANGE_SHOP_ITEM_CURRENT_TEST_ID';
export const CHANGE_SHOP_ITEM_TEST_ID = 'CHANGE_SHOP_ITEM_TEST_ID';
export const CHANGE_SHOP_ADDRESS_TEST_ID = 'CHANGE_SHOP_ADDRESS_TEST_ID';
export const CHANGE_SHOP_BTN_LOADING_TEST_ID = 'CHANGE_SHOP_BTN_LOADING_TEST_ID';
