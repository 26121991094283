import React, { KeyboardEvent, memo } from 'react';
import Input, { EnumInputErrorTypes } from 'Components/Input/Input';
import { EnumInputSize, EnumInputTheme } from 'constants/enums';
import { ADDRESS_DROPDOWN_INPUT_TEST_ID } from './constants';

interface ISearchInputProps {
  size: EnumInputSize;
  after: JSX.Element;
  before?: JSX.Element;
  value: string;
  innerRef: React.MutableRefObject<HTMLInputElement | null>;
  autoFocus?: boolean;
  onSubmit: (query?: string, e?: React.FormEvent<HTMLFormElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus: () => void;
  error?: boolean;
  use?: EnumInputTheme;
}

const SearchInput: React.FC<ISearchInputProps> = ({
  size,
  before,
  after,
  value,
  innerRef,
  autoFocus,
  onSubmit,
  onChange,
  onFocus,
  error,
  use,
}) => {
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSubmit();
    }
  };
  return (
    <Input
      ref={innerRef}
      size={size}
      use={use}
      placeholder="Укажите адрес доставки"
      after={after}
      before={before}
      onKeyPress={handleKeyPress}
      onChange={onChange}
      autoFocus={autoFocus}
      onFocus={onFocus}
      name="address_dropdown_desktop"
      value={value}
      testId={ADDRESS_DROPDOWN_INPUT_TEST_ID}
      errorType={EnumInputErrorTypes.tooltip}
      error={error}
    />
  );
};

export default memo(SearchInput);
