import React, { FC, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { EnumButtonSize, ProductStates } from 'constants/enums';
import { Flex } from '@igooods/ui-kit';
import {
  PRODUCT_ADD_BUTTON_TEST_ID,
  PRODUCT_BACK_IN_CART_BUTTON_TEST_ID,
  PRODUCT_FIND_REPLACEMENT_BUTTON_TEST_ID,
} from './constants';
import { themeCardByWL } from './themes';
import IncreaseIcon from 'Components/Icon/IncreaseIcon';
import NewCartIcon from 'Components/Icon/NewCartIcon';
import { ControlsButton, QuantityStyled } from './styled';
import { colors } from 'constants/colors';

interface IControlsProps {
  quantity: number;
  weight: number;
  state: ProductStates;
  onAddToCart?: () => void;
  onAddToOrder?: () => void;
  canAddToOrder: boolean;
  onQuantityChange?: (amount: number, weight?: number) => void;
  isMobileControlsVisible: boolean;
  toggleMobileControls: (val: boolean) => void;
  showWeight?: boolean;
  className?: string;
  onRevert?: () => void;
  onFindReplacement?: () => void;
  isAmountOverLimit?: boolean;
}

export const DesktopButtonsWrapper = styled(Flex).attrs({ hideBefore: 'sm', column: true })`
  ${ControlsButton} + ${ControlsButton} {
    margin-top: 8px;
  }
`;

export const OrderButton = styled(ControlsButton)`
  flex-grow: 1;
`;

const StyledPlusIcon = styled(IncreaseIcon)`
  color: ${colors.white};
`;

const StyledCartIcon = styled(NewCartIcon)`
  color: ${colors.white};
`;

const Controls: FC<IControlsProps> = ({
  state,
  onAddToCart,
  onAddToOrder,
  onQuantityChange,
  canAddToOrder,
  quantity,
  showWeight,
  weight,
  className,
  onRevert,
  onFindReplacement,
  isAmountOverLimit = false,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const { addToBasketText } = useContext<typeof themeCardByWL>(ThemeContext);

  return (
    <div className={className}>
      {
        {
          [ProductStates.toBasket]: (
            <>
              <DesktopButtonsWrapper>
                {onAddToCart && (
                  <ControlsButton
                    before={<StyledCartIcon width="18" height="18" color="currentColor" />}
                    data-testid={PRODUCT_ADD_BUTTON_TEST_ID}
                    onClick={onAddToCart}
                    fluid
                  >
                    {addToBasketText}
                  </ControlsButton>
                )}
                {canAddToOrder && onAddToOrder && (
                  <OrderButton
                    before={<StyledPlusIcon width="18" height="18" color="currentColor" />}
                    size={EnumButtonSize.small}
                    onClick={onAddToOrder}
                    fluid
                  >
                    В заказ
                  </OrderButton>
                )}
              </DesktopButtonsWrapper>
              <Flex end hideAfter="sm">
                <ControlsButton
                  size={EnumButtonSize.large}
                  data-testid={PRODUCT_ADD_BUTTON_TEST_ID}
                  onClick={onAddToCart}
                  icon
                >
                  <StyledPlusIcon color="currentColor" />
                </ControlsButton>
              </Flex>
            </>
          ),
          [ProductStates.inBasket]: onQuantityChange && (
            <QuantityStyled
              isKomus
              weight={weight}
              quantity={quantity}
              onChange={onQuantityChange}
              showWeight={showWeight}
              isAmountOverLimit={isAmountOverLimit}
            />
          ),
          [ProductStates.revert]: (
            <ControlsButton onClick={onRevert} data-testid={PRODUCT_BACK_IN_CART_BUTTON_TEST_ID} fluid>
              Вернуть
            </ControlsButton>
          ),
          [ProductStates.notAvailable]: onFindReplacement && (
            <ControlsButton onClick={onFindReplacement} fluid data-testid={PRODUCT_FIND_REPLACEMENT_BUTTON_TEST_ID}>
              Найти замену
            </ControlsButton>
          ),
        }[state]
      }
    </div>
  );
};

export default Controls;
