import React from 'react';
import Icon, { IIcon } from './Icon';

const CircleCheckIcon: React.FC<IIcon> = props => (
  <Icon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <circle cx="9" cy="9" r="8" fill="#1A1A2C" />
    <circle cx="9" cy="9" r="8.5" stroke="white" strokeOpacity="0.1" />
    <path d="M6 9.0549L8.2 11L12 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);

export default CircleCheckIcon;
