import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { colors } from 'constants/colors';
import Flex from 'Components/Grid/Flex';
import Box from '../../Components/Grid/Box';
import { getMediaFromProp, IMediaProps } from 'theme/helpers';
import ProductCol from '../../Components/Products/ProductCol';
import HeartIcon from '../../Components/Icon/HeartIcon';
import { mediaSmDown } from 'theme/helpers/css';
import { CATEGORY_LOADING_TEST_ID, CATEGORY_PRODUCTS_LOADING_TEST_ID } from './constants';

const shineLines = keyframes`
   0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 320px;
  }
`;

export const loaderAnimation = css`
  background-image: linear-gradient(
    90deg,
    ${colors.paleGrey} 0px,
    ${colors.paleGreyThree} 80px,
    ${colors.paleGrey} 160px
  );
  background-size: 600px;
  animation: 1.8s infinite linear;
  animation-name: ${shineLines};
`;

interface ILoadable {
  isStatic?: boolean;
}

interface ILoadingFilterProps extends ILoadable, IMediaProps {
  widthPx?: number;
  heightPx: number;
  marginTop?: number;
}

export const LoadingBlock = styled(Flex)<ILoadingFilterProps>`
  ${({ widthPx }) => (widthPx ? `width: ${widthPx}px;` : 'width: 100%;')}
  height: ${({ heightPx }) => heightPx}px;
  background-color: ${colors.paleGrey};
  margin-top: ${({ marginTop = 0 }) => marginTop}px;
  margin-right: 20px;
  border-radius: 4px;
  ${({ isStatic }) => !isStatic && loaderAnimation}
  ${getMediaFromProp}
`;

const LoadingProductPhoto = styled.div<ILoadable>`
  width: 100%;
  min-width: 116px;
  height: 116px;
  background-color: ${colors.paleGrey};
  ${({ isStatic }) => !isStatic && loaderAnimation}

  ${mediaSmDown`
    min-width: 106px; 
    height: 106px;
  `}
`;

const ProductGridCol = styled(ProductCol)`
  height: 380px;

  ${mediaSmDown`
    height: 240px;
  `}
`;
interface IProductLoading extends ILoadable {
  isFavorite?: boolean;
  length?: number;
  className?: string;
}

export const ProductLoading: React.FC<IProductLoading> = ({ isFavorite, isStatic, className }) => (
  <ProductGridCol className={className}>
    <Flex column>
      <Flex>
        <LoadingProductPhoto isStatic={isStatic} />
        {isFavorite && (
          <Box ml={3}>
            <HeartIcon color={colors.bubbleGumPink} width="24px" />
          </Box>
        )}
      </Flex>
      <LoadingBlock inDesktop isStatic={isStatic} widthPx={110} heightPx={30} marginTop={10} />
      <LoadingBlock inDesktop isStatic={isStatic} widthPx={190} heightPx={15} marginTop={15} />
      <LoadingBlock inDesktop isStatic={isStatic} widthPx={120} heightPx={15} marginTop={10} />
      <LoadingBlock inMobile isStatic={isStatic} widthPx={80} heightPx={20} marginTop={8} />
      <LoadingBlock inMobile isStatic={isStatic} widthPx={120} heightPx={15} marginTop={8} />
      <LoadingBlock inMobile isStatic={isStatic} widthPx={100} heightPx={15} marginTop={4} />
    </Flex>
  </ProductGridCol>
);

const numberProductsDefault = 8;

export const ProductListLoading: React.FC<IProductLoading> = ({
  isFavorite,
  isStatic,
  length = numberProductsDefault,
}) => (
  <Flex fluid wrap mx={-2} mxXs={-2} data-testid={CATEGORY_PRODUCTS_LOADING_TEST_ID}>
    {[...new Array(length)].map((_, index) => (
      <ProductLoading isFavorite={isFavorite} key={index} isStatic={isStatic} />
    ))}
  </Flex>
);

const CategoryLoading: React.FC = () => {
  return (
    <Flex column px={12} py={10} pxXs={4} pyXs={4} data-testid={CATEGORY_LOADING_TEST_ID}>
      <Flex>
        <LoadingBlock inDesktop widthPx={320} heightPx={40} />
        <LoadingBlock inMobile widthPx={220} heightPx={30} />
      </Flex>
      <Flex mtXs={5} mt={5}>
        <LoadingBlock inDesktop widthPx={120} heightPx={40} />
        <LoadingBlock inDesktop widthPx={80} heightPx={40} />
        <LoadingBlock inDesktop widthPx={140} heightPx={40} />
        <LoadingBlock inDesktop widthPx={100} heightPx={40} />
        <LoadingBlock inMobile widthPx={120} heightPx={30} />
        <LoadingBlock inMobile widthPx={80} heightPx={30} />
        <LoadingBlock inMobile widthPx={140} heightPx={30} />
      </Flex>
      <Flex mtXs={4} mt={12} mbXs={6} mb={12}>
        <LoadingBlock inDesktop widthPx={600} heightPx={40} />
        <LoadingBlock inMobile widthPx={200} heightPx={30} />
      </Flex>
      <ProductListLoading />
    </Flex>
  );
};

export default CategoryLoading;
