import React from 'react';
import Icon, { IIcon } from './Icon';

const ChevronRoundedIcon: React.FC<IIcon> = props => (
  <Icon width="10" height="6" viewBox="0 0 10 6" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L5 3.58579L8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893C10.0976 0.683417 10.0976 1.31658 9.70711 1.70711L5.70711 5.70711C5.31658 6.09763 4.68342 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
      fill="currentColor"
    />
  </Icon>
);

export default ChevronRoundedIcon;
