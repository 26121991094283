import styled from 'styled-components';
import { getMultipliedIndentFromProp, getTypographyFontStyle } from 'theme/selectors';
import { FontFamily, FontSize, FontWeight } from 'theme';
import { colors } from '../../constants/colors';
import { mediaSmDown } from 'theme/helpers/css';
import { addStyleIfPropTruly } from 'utils/styledComponents';
import Text from '../Typography/Text';
import SortIcon from '../Icon/SortIcon';
import { directSortStates } from 'constants/enums';
import { ITabStyled } from './types';

export const Tab = styled.div<ITabStyled>`
  ${getTypographyFontStyle(FontSize.s)};
  color: ${colors.dark300};
  margin: 0 ${getMultipliedIndentFromProp(4)};
  position: relative;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-bottom: 12px;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  &:after {
    content: '';
    height: 1px;
    background-color: ${colors.darkGrey};
    position: absolute;
    opacity: 0;
    width: 100%;
    left: 0;
    bottom: 0;
  }

  ${mediaSmDown`
    padding-bottom: 8px;
    flex-shrink: 0;

    ${getTypographyFontStyle(FontSize.xs)}
  `}

  ${addStyleIfPropTruly(
    'active',
    `
    color: ${colors.darkGrey};
    &:after { opacity: 1; }
    `
  )}
`;

export const ProductChip = styled.button<{ isChipOnFocus?: boolean }>`
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 0 5px 8px 0;
  padding: 0 16px 1px;
  width: fit-content;
  height: 36px;
  background-color: ${colors.light200};
  font-weight: ${FontWeight.medium};
  font-family: ${FontFamily.mainFont};
  border-radius: 100px;
  cursor: pointer;
  ${({ isChipOnFocus }) =>
    isChipOnFocus &&
    `
      background-color: ${colors.primaryAction};
    `}
`;

export const ChipName = styled(Text).attrs({ color: colors.dark300 })<{
  isShowMore?: boolean;
  isChipOnFocus?: boolean;
}>`
  letter-spacing: -0.2px;
  font-size: 14px;
  ${({ isShowMore }) =>
    isShowMore &&
    `
      color: ${colors.dark300};
    `};

  ${({ isChipOnFocus }) =>
    isChipOnFocus &&
    `
      color: ${colors.white};
    `}
`;

export const StyledSortIcon = styled(SortIcon)<ITabStyled>`
  margin-left: 4px;
  opacity: 0;

  ${addStyleIfPropTruly(
    'active',
    `
    opacity: 1;
    `
  )}

  ${({ direction }) =>
    direction === directSortStates.desc &&
    `
      transform: scaleY(-1);
    `}
`;
