import React from 'react';
import styled from 'styled-components';
import { Box, Flex } from '@igooods/ui-kit';
import { colors } from 'constants/colors';
import { mediaSmDown, mediaSmUp } from 'theme/helpers/css';
import { Text } from 'Components/Typography';
import ThemedButton from 'Components/ThemedButton';
import { EnumButtonSize, EnumTheme } from 'constants/enums';
import QuestionMarkIcon from 'Components/Icon/QuestionMarkIcon';

export const CART_NOTIFICATION = 'CART_NOTIFICATION';

const Container = styled(Flex).attrs({ mt: -7, mb: 10.25, px: 4.75, middle: true })<{ $bgColor: string }>`
  ${({ $bgColor }) => `
    background-color: ${$bgColor};
    border-radius: 12px;
    height: 80px; 

    ${mediaSmUp`
      width: 420px;
      &:not(:first-child) {
        margin-left: 28px;
      } 
    `}
  `}
`;

const QuestionMark = styled(ThemedButton).attrs({
  use: EnumTheme.roundWhite,
  size: EnumButtonSize.small,
})`
  padding: 0;
  height: 22px;
  width: 22px;
  border-radius: 11px;
  background-color: ${colors.white};
`;

const Image = styled.img`
  width: 69px;
  height: auto;
`;

const TextStyled = styled(Text)`
  line-height: 20px;
  letter-spacing: -0.2px;
  ${mediaSmDown`
    font-size: 12px;
    line-height: 16px;  
  `}
`;

const TextWrapper = styled(Box)`
  max-width: 270px;
`;

interface ICartNotificationProps {
  backgroundColor: string;
  imageSrc: string;
  text: string;
  isQuestion?: boolean;
  onQuestionClick?: () => void;
}

const CartNotification: React.FC<ICartNotificationProps> = ({
  backgroundColor,
  imageSrc,
  text,
  isQuestion,
  onQuestionClick,
}) => {
  return (
    <Container data-testid={CART_NOTIFICATION} $bgColor={backgroundColor}>
      <Box pb={0.25} mr={2.5}>
        <Image src={imageSrc} />
      </Box>
      <TextWrapper pr={{ sm: 3 }}>
        <TextStyled>{text}</TextStyled>
      </TextWrapper>
      {isQuestion && (
        <Box ml mr={-0.25}>
          <QuestionMark onClick={onQuestionClick}>
            <QuestionMarkIcon color={colors.blackRussian} />
          </QuestionMark>
        </Box>
      )}
    </Container>
  );
};

export default CartNotification;
