import React from 'react';
import Icon, { IIcon } from './Icon';

const BadgeNewIcon: React.FC<IIcon> = props => (
  <Icon width="31" height="22" viewBox="0 0 31 22" fill="none" {...props}>
    <path
      d="M16.9975 20.392C12.8137 21.7095 8.88015 21.8039 5.90897 20.9302C2.9398 20.0571 0.995789 18.2473 0.582289 15.7705C0.163395 13.2615 1.36604 10.4013 3.80305 7.79458C6.22947 5.19918 9.82119 2.9247 14.0025 1.608C18.1863 0.290543 22.1199 0.196148 25.091 1.06982C28.0602 1.9429 30.0042 3.75271 30.4177 6.22947C30.8366 8.73853 29.634 11.5987 27.197 14.2054C24.7705 16.8008 21.1788 19.0753 16.9975 20.392Z"
      fill="#FFE468"
      stroke="white"
    />
    <path
      d="M6 13.5L7.37578 8.5H8.5157L9.89148 12.0948L10.8813 8.5H11.914L10.5383 13.5H9.39477L8.01185 9.90865L7.03273 13.5H6Z"
      fill="#1A1A2C"
    />
    <path
      d="M11.8533 13.5L13.2291 8.5H16.8525L16.5953 9.41702H14.1296L13.8294 10.5084H15.9449L15.6948 11.401H13.5793L13.2505 12.5865H15.7912L15.5411 13.5H11.8533Z"
      fill="#1A1A2C"
    />
    <path
      d="M17.5136 13.5L17.3242 8.5H18.4427L18.5392 11.9658L20.5797 8.5H21.641L21.7946 12.0216L23.7779 8.5H25L22.0591 13.5H20.9334L20.7262 9.97141L18.5964 13.5H17.5136Z"
      fill="#1A1A2C"
    />
  </Icon>
);

export default BadgeNewIcon;
