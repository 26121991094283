import React from 'react';
import Icon, { IIcon } from './Icon';

const ArrowLeftIconNew: React.FC<IIcon> = props => (
  <Icon width="9" height="16" viewBox="0 0 9 16" fill="none" {...props}>
    <path d="M8 1L1 8L8 15" stroke="#1A1A2C" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);

export default ArrowLeftIconNew;
