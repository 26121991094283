import { IGeoGrinderPlace } from 'Modules/GeoGrinder/types';
import React, { useEffect, useRef, useState } from 'react';
import GeoGrinderHttp from 'Modules/GeoGrinder/http';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentShopId } from 'Modules/Shops/selectors';
import { getCurrentAddressName, getCurrentCityId, getCurrentCity } from 'Modules/SearchAddress/selectors';
import { getCuttedQuery, cuttedAddressName, getCuttedPlaceFromMap } from './utils';
import {
  LOCAL_STORAGE_IS_FETCHING_SUGGESTIONS_KEY,
  LOCATION_BTN_TEST_ID,
  SearchAddressFromType,
  SearchAddressSizes,
  SEARCH_ADDRESS_BTN_TEST_ID,
  SEARCH_ADDRESS_FORM_TEST_ID,
} from './constants';
import { EnumButtonSize, EnumInputSize, EnumNotification, EnumTheme } from 'constants/enums';
import {
  Form,
  FormTitle,
  InputContainer,
  LocationBtn,
  LocationBtnLanding,
  MapBtn,
  SubmitBtnContainer,
  StyledAddressInput,
  SearchAddressButton,
  TitleWrap,
} from './styled';
import { Text } from 'Components/Typography';
import { FontFamily, FontSize, FontWeight } from 'theme';
import { colors } from 'constants/colors';
import { useDebounce } from 'utils/hooks';
import { isBrowser } from 'utils/helpers';
import { setNotificationAction } from 'Modules/Notifications/actions';
import geoGrinderPlaceToPlace from 'Modules/GeoGrinder/utils/geoGrinderPlaceToPlace';
import { getCurrentPosition, setCurrentAddressAction } from 'Modules/SearchAddress/actions';
import SuggestionsDropdown from './SuggestionsDropdown';
import { Flex } from '@igooods/ui-kit';
import { ISearchAddressProps } from './Desktop';
import { useHistory } from 'react-router';
import { LOCAL_STORAGE_ADDRESS_FROM_MAP_KEY } from 'utils/ya_map/setMapListeners';
import { EnumInputTheme } from 'constants/enums';
import { KeyCode } from 'constants/enums';

export const ERROR_EMPTY = 'Введите свой адрес';
export const ERROR_NOT_FULL = 'Введите полный адрес';
export const ERROR_NOT_FOUND = 'Не удалось найти адрес, попробуйте выбрать его на карте';

const MIN_LENGTH_SEARCH_QUERY = 3;
const NOTIFICATION_DURATION = 3000;

const sizes = {
  [SearchAddressSizes.big]: {
    inputSize: EnumInputSize.large,
    btnSize: EnumButtonSize.large,
    btnWidth: '200',
    withError: true,
  },
  [SearchAddressSizes.small]: {
    inputSize: EnumInputSize.normal,
    btnSize: EnumButtonSize.normal,
    btnWidth: '160px',
    withError: false,
  },
};

const SearchAddressContent: React.FC<ISearchAddressProps> = ({
  size = SearchAddressSizes.big,
  from = SearchAddressFromType.select_address,
  mapBtnTestId,
  inputTestId,
  autoFocus,
  onSelectAddress,
  use,
  hideButton = false,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const cityId = useSelector(getCurrentCityId);
  const city = useSelector(getCurrentCity);
  const currentAddressName = useSelector(getCurrentAddressName);
  const currentShop = useSelector(getCurrentShopId);
  const addressName = cuttedAddressName(currentAddressName);
  const inputContainerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [selectedAddress, setSelectedAddress] = useState(addressName || '');
  const [suggestions, setSuggestions] = useState<IGeoGrinderPlace[]>([]);
  const [query, setQuery] = useState<string>(addressName || '');
  const [currentCityId, setCurrentCityId] = useState(cityId);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuggestion, setShowSuggestions] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [isFull, setIsFull] = useState(Boolean(currentAddressName));
  const debouncedQuery = useDebounce(query, 500);
  const [inputError, setInputError] = useState(false);
  const isSearchValid = Boolean(query.match(/\d+/)) && isFull;

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.keyCode === KeyCode.Enter && isLoading) {
        e.preventDefault();
      }
    };

    if (isBrowser) {
      window.addEventListener('keydown', keydownHandler);
    }
    return () => {
      if (isBrowser) {
        window.removeEventListener('keydown', keydownHandler);
      }
    };
  }, [isLoading]);

  useEffect(() => {
    const placeFromMapString = sessionStorage.getItem(LOCAL_STORAGE_ADDRESS_FROM_MAP_KEY);
    if (placeFromMapString) {
      try {
        const placeFromMap: string = getCuttedPlaceFromMap(JSON.parse(placeFromMapString));
        setQuery(placeFromMap);
        setSelectedAddress(placeFromMap);
      } catch (error) {
        setInputError(true);
        dispatch(setNotificationAction(ERROR_NOT_FOUND, EnumNotification.danger, NOTIFICATION_DURATION));
      } finally {
        sessionStorage.removeItem(LOCAL_STORAGE_ADDRESS_FROM_MAP_KEY);
      }
    }
    // eslint-disable-next-line
  }, [currentAddressName]);

  const handleFocusInput = () => setInputFocused(true);

  const fetchSuggestions = async () => {
    const storageQuery = localStorage.getItem('addressQuery');
    if (debouncedQuery && debouncedQuery !== storageQuery) {
      localStorage.setItem('addressQuery', debouncedQuery);
      try {
        setSuggestions([]);
        sessionStorage.setItem(LOCAL_STORAGE_IS_FETCHING_SUGGESTIONS_KEY, 'true');
        setIsLoading(true);
        const suggestionsList = await GeoGrinderHttp.fetchSuggestions(cityId, debouncedQuery);
        await setSuggestions(suggestionsList);
        if (autoFocus) inputRef.current?.focus();
      } catch (error) {
        if (!isSearchValid) {
          setInputError(true);
          dispatch(setNotificationAction(ERROR_NOT_FOUND, EnumNotification.danger, NOTIFICATION_DURATION));
        }
      } finally {
        setIsLoading(false);
        sessionStorage.removeItem(LOCAL_STORAGE_IS_FETCHING_SUGGESTIONS_KEY);
        localStorage.removeItem('addressQuery');
      }
    }
  };

  useEffect(() => {
    if (query.includes('undefined')) {
      history.push('/?select_address=false');
    }
  }, [history, query]);

  useEffect(() => {
    if (!currentShop) {
      setQuery('');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      if (!inputContainerRef.current?.contains(event.target as Node)) {
        setShowSuggestions(false);
        setInputFocused(false);
      }
    };
    if (isBrowser) {
      window.addEventListener('click', clickHandler);
    }
    return () => {
      if (isBrowser) {
        window.removeEventListener('click', clickHandler);
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentAddressName && addressName && query !== addressName) {
      setQuery(addressName);
    }
    // eslint-disable-next-line
  }, [addressName]);

  useEffect(() => {
    (async () => {
      await fetchSuggestions();
      if (debouncedQuery.length < MIN_LENGTH_SEARCH_QUERY && suggestions.length) {
        setShowSuggestions(false);
        setSuggestions([]);
      }
    })();
    // eslint-disable-next-line
  }, [debouncedQuery]);

  useEffect(() => {
    if (query && debouncedQuery === query) {
      setIsFull(true);
    } else if (isSearchValid) {
      setInputError(false);
    }
    // eslint-disable-next-line
  }, [isFull, selectedAddress, debouncedQuery, query, isSearchValid]);

  useEffect(() => {
    if (suggestions.length && query.length > MIN_LENGTH_SEARCH_QUERY) setShowSuggestions(true);
  }, [inputFocused, query.length, suggestions.length]);

  const handleSubmit = async (autoSubmitQuery?: string, event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    const submitQuery = autoSubmitQuery || query;
    const hasNumberinQuery = Boolean(submitQuery?.match(/\d+/));
    try {
      if (isFull && hasNumberinQuery) {
        const modifiedQuery = `${city.name}, ${submitQuery}`;
        const place = await GeoGrinderHttp.fetchPlaceBySuggestion(modifiedQuery);
        dispatch(setCurrentAddressAction(geoGrinderPlaceToPlace(place)));
        onSelectAddress();
        return;
      } else {
        setInputError(true);
        if (debouncedQuery.length) {
          return dispatch(setNotificationAction(ERROR_NOT_FULL, EnumNotification.danger, NOTIFICATION_DURATION));
        } else {
          return dispatch(setNotificationAction(ERROR_EMPTY, EnumNotification.danger, NOTIFICATION_DURATION));
        }
      }
    } catch (error) {
      setInputError(true);
      dispatch(setNotificationAction(ERROR_NOT_FOUND, EnumNotification.danger, NOTIFICATION_DURATION));
    }
    //eslint-disable-next-line
  };

  const handleClickMapBtn = () => {
    history.push('/?select_address=true');
  };

  const handleClickLocation = () => {
    getCurrentPosition(dispatch, cityId);
  };

  useEffect(() => {
    if (cityId !== currentCityId) {
      setQuery('');
      setCurrentCityId(cityId);
    }
  }, [cityId, currentCityId]);

  const changeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputError(false);
    setSelectedAddress('');
    setQuery(event.currentTarget.value);
    setIsFull(false);
  };

  const onSelectSuggestion = async (suggestion: IGeoGrinderPlace) => {
    const cuttedQuery = getCuttedQuery(suggestion);
    setQuery(cuttedQuery);
    setInputError(false);
    if (suggestion.full) {
      setSelectedAddress(cuttedQuery);
      setShowSuggestions(false);
      setIsFull(true);

      if (hideButton) {
        handleSubmit(cuttedQuery);
      }
    }
    setSuggestions([]);
    if (autoFocus) inputRef.current?.focus();
  };

  const propertiesByFromType = {
    [SearchAddressFromType.select_address]: {
      after: <LocationBtn data-testid={LOCATION_BTN_TEST_ID} onClick={handleClickLocation} />,
      before: undefined,
      btnText: 'Везите сюда',
    },
    [SearchAddressFromType.main]: {
      after: (
        <MapBtn onClick={handleClickMapBtn} data-testid={mapBtnTestId}>
          <Text
            fontFamily={FontFamily.secondFont}
            size={FontSize.xs}
            color={colors.blackRussian}
            fontWeight={FontWeight.semiBold}
          >
            Карта
          </Text>
        </MapBtn>
      ),
      before: <LocationBtnLanding data-testid={LOCATION_BTN_TEST_ID} onClick={handleClickLocation} />,
      btnText: 'Выбрать магазин',
    },
  };

  const { inputSize, btnSize, btnWidth } = sizes[size];

  const { after, before, btnText } = propertiesByFromType[from];

  return (
    <Flex center middle column>
      <TitleWrap column>
        {from !== SearchAddressFromType.main && <FormTitle>Куда привезти продукты?</FormTitle>}
        {/*
        // @ts-ignore */}
        <Form onSubmit={handleSubmit} data-testid={SEARCH_ADDRESS_FORM_TEST_ID}>
          <InputContainer ref={inputContainerRef}>
            <StyledAddressInput
              size={inputSize}
              after={after}
              before={before}
              onSubmit={handleSubmit}
              onChange={changeQuery}
              onFocus={handleFocusInput}
              value={query}
              innerRef={inputRef}
              data-testid={inputTestId}
              autoFocus={autoFocus}
              error={inputError}
              use={use || EnumInputTheme.roundSecondary}
            />
            <SuggestionsDropdown suggestions={suggestions} show={showSuggestion} onSelect={onSelectSuggestion} />
          </InputContainer>
          {!hideButton && (
            <SubmitBtnContainer $width={btnWidth}>
              <SearchAddressButton
                size={btnSize}
                use={EnumTheme.roundRed}
                type="submit"
                isLoading={isLoading}
                testId={SEARCH_ADDRESS_BTN_TEST_ID}
                fluid
                onClick={(event: React.FormEvent<HTMLFormElement>) => handleSubmit(undefined, event)}
              >
                {btnText}
              </SearchAddressButton>
            </SubmitBtnContainer>
          )}
        </Form>
      </TitleWrap>
    </Flex>
  );
};

export default SearchAddressContent;
