export const ADDRESS_DROPDOWN_LIST_ITEM_TEST_ID = 'ADDRESS_DROPDOWN_LIST_ITEM_TEST_ID';
export const ADDRESS_DROPDOWN_TEST_ID = 'ADDRESS_DROPDOWN_TEST_ID';
export const ADDRESS_DROPDOWN_LIST_TEST_ID = 'ADDRESS_DROPDOWN_LIST_TEST_ID';
export const ADDRESS_DROPDOWN_INPUT_TEST_ID = 'ADDRESS_DROPDOWN_INPUT_TEST_ID';
export const SEARCH_ADDRESS_FORM_TEST_ID = 'SEARCH_ADDRESS_FORM_TEST_ID';
export const SEARCH_ADDRESS_BTN_TEST_ID = 'SEARCH_ADDRESS_BTN_TEST_ID';
export const LOCATION_BTN_TEST_ID = 'LOCATION_BTN_TEST_ID';

export enum SearchAddressSizes {
  big = 'big',
  small = 'small',
}

export enum SearchAddressFromType {
  select_address = 'select_address',
  main = 'main',
}

export const LOCAL_STORAGE_IS_FETCHING_SUGGESTIONS_KEY = 'LOCAL_STORAGE_IS_FETCHING_SUGGESTIONS_KEY';
