import styled from 'styled-components';
import { Box, BoxProps } from '@igooods/ui-kit';
import { mediaSmUp, mediaLgUp, mediaCustomUp, mediaXlUp } from '../../theme/helpers/css';

export const productColIndents = {
  px: {
    xs: 5,
    sm: 6,
    lg: 6,
  },
  py: {
    xs: 5,
    lg: 5.75,
  },
  pb: {
    sm: 15.5,
    lg: 15.5,
  },
};

const ProductCol = styled(Box).attrs(
  props =>
    ({
      px: productColIndents.px,
      py: productColIndents.py,
      pb: productColIndents.pb,
      ...props,
    } as BoxProps)
)`
  position: relative;
  flex-basis: 50%;
  max-width: 50%;

  ${mediaSmUp`
    flex-basis: ${100 / 3}%;
    max-width: ${100 / 3}%;
  `}

  ${mediaLgUp`
    flex-basis: 25%;
    max-width: 25%;
  `}

  ${mediaCustomUp(1440)`
    flex-basis: 20%;
    max-width: 20%;
  `}

  ${mediaXlUp`
    flex-basis: ${100 / 5}%;
    max-width: ${100 / 5}%;
  `}

  ${mediaCustomUp(1920)`
    flex-basis: ${100 / 6}%;
    max-width: ${100 / 6}%;
  `}
`;

export default ProductCol;
