import React from 'react';
import Title from 'Components/Typography/Title';
import { FontFamily } from 'theme';
import { colors } from 'constants/colors';
import { mediaSmDown, mediaCustomUp } from 'theme/helpers/css';
import styled from 'styled-components';
import EditIcon from 'Components/Icon/EditIcon';
import { IShopForPlace } from 'Modules/Shops/types';
import ShopCard from 'Containers/SelectShop/ShopCard/ShopCard';
import { FontWeight, FontSize } from 'theme';
import { getTypographyFontStyle } from 'theme/selectors';
import { getCurrentShopId } from 'Modules/Shops/selectors';
import { useSelector } from 'react-redux';
import Loader from 'Components/Loader';
import { Box, Grid } from '@igooods/ui-kit';
import { Spring, animated } from 'react-spring';

export const SELECT_SHOP_TEST_ID = 'SELECT_SHOP_TEST_ID';
export const SELECT_SHOP_EDIT_BTN_TEST_ID = 'SELECT_SHOP_EDIT_BTN_TEST_ID';

const LoadingBox = styled(Box)`
  align-self: center;
  width: 100%;
`;

const ChangeAddressBox = styled(Box)`
  ${mediaSmDown`
    word-break: break-all;
  `}
`;

const Place = styled(Title).attrs({
  fontWeight: FontWeight.bold,
  size: FontSize.l,
  color: colors.dark300,
  fontFamily: FontFamily.secondFont,
})`
  ${mediaSmDown`
    ${getTypographyFontStyle(FontSize.l)}
  `}
`;

const EditBtn = styled.div`
  cursor: pointer;
  position: relative;
`;

const EditIconWrapper = styled.div`
  width: 13px;
  height: 13px;
  margin-top: 11px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translate(8px, -5px);
  color: ${colors.dark200};

  img {
    width: 13px;
  }
`;

const ShopsTitle = styled(Title)`
  font-size: 32px;
  ${mediaCustomUp(1920)`font-size: 38px; line-heith: 46px;`}
  ${mediaSmDown`
     padding-left: 4px;
  `}
`;

const ShopCardList = styled(Grid).attrs({
  col: { xs: 1, sm: 3, lg: 4 },
  gapCol: 5,
})`
  grid-row-gap: 74px;

  ${mediaSmDown`
    grid-row-gap: 32px;
  `}
`;

interface ISelectShop {
  className?: string;
  address?: string;
  onClickEditPlace?: () => void;
  shops: IShopForPlace[];
  onSelectShop: (shop: IShopForPlace) => void;
  isChangeShopLoading: boolean;
}

const SelectShop: React.FC<ISelectShop> = ({
  className,
  address,
  onClickEditPlace,
  shops,
  onSelectShop,
  isChangeShopLoading,
}) => {
  const currentShopId = useSelector(getCurrentShopId);

  return isChangeShopLoading ? (
    <LoadingBox pt={11} pb={10}>
      <Loader />
    </LoadingBox>
  ) : (
    <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
      {styles => (
        <animated.div style={styles}>
          <Box mb={{ sm: 23 }} className={className} data-testid={SELECT_SHOP_TEST_ID}>
            {address && (
              <ChangeAddressBox mb={{ xs: 3, sm: 4 }} pr={{ xs: 30, sm: 20 }} pl={{ xs: 1 }}>
                <Place>
                  {onClickEditPlace ? (
                    <EditBtn onClick={onClickEditPlace} data-testid={SELECT_SHOP_EDIT_BTN_TEST_ID}>
                      {address}
                      <EditIconWrapper>
                        <EditIcon />
                      </EditIconWrapper>
                    </EditBtn>
                  ) : (
                    address
                  )}
                </Place>
              </ChangeAddressBox>
            )}
            <ShopsTitle fontWeight={FontWeight.semiBold} color={colors.dark300} size={FontSize.xxxl}>
              Из какого магазина привезти?
            </ShopsTitle>
            <Box mt={{ xs: 11, sm: 20 }} pbXs={15}>
              <ShopCardList>
                {shops.map((shop: IShopForPlace) => (
                  <ShopCard
                    shop={shop}
                    key={shop.id}
                    onSelectShop={onSelectShop}
                    selected={currentShopId === shop.id}
                  />
                ))}
              </ShopCardList>
            </Box>
          </Box>
        </animated.div>
      )}
    </Spring>
  );
};

export default SelectShop;
