import React, { ReactChild, ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from 'constants/colors';
import { shadows } from 'constants/shadows';
import { Box, Flex } from '@igooods/ui-kit';
import Scrollbar from 'react-scrollbars-custom';
import { mediaMobile } from 'theme/helpers/css';

interface IScrollbarDropdown {
  children: ReactNode | ReactChild;
}

const Container = styled(Box).attrs({ px: 1 })`
  position: absolute;
  z-index: 1;
  width: 100%;
  border-radius: 24px;
  box-shadow: ${shadows.boxShadow};
  top: calc(100% + 10px);
  background-color: ${colors.white};
  ${mediaMobile`
    z-index: 1;
  `}
`;

const CustomTrack = styled(Box).attrs({ py: 6.5 })`
  position: absolute;
  overflow: hidden;
  user-select: none;
  width: 3px;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 2;
  transition: width 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  will-change: width;

  &:hover,
  &:active,
  &:focus {
    width: 6px;
  }
`;

const CustomThumb = styled.div`
  touch-action: none;
  cursor: pointer;
  border-radius: 100px;
  background: ${colors.lavender};
  width: 100%;
  opacity: 0;
`;

const CustomScrollbar = styled(Scrollbar).attrs(() => ({
  noScrollX: true,
  disableTrackYWidthCompensation: true,
  maximalThumbYSize: 28,
  translateContentSizeYToHolder: true,
  trackYProps: {
    renderer: function Render(props) {
      const { elementRef, children } = props;
      return <CustomTrack ref={elementRef}>{children}</CustomTrack>;
    },
  },
  thumbYProps: {
    renderer: function Render(props) {
      const { elementRef } = props;
      return <CustomThumb ref={elementRef} />;
    },
  },
  contentProps: { style: { display: 'block' } },
}))<{ $maxHeight?: number; $minHeight?: number }>`
  height: 100%;
  ${({ $maxHeight }) => $maxHeight && `max-height: ${$maxHeight}px;`}
  ${({ $minHeight }) => $minHeight && `min-height: ${$minHeight}px;`}
  ${mediaMobile`
    max-height: 100px;
  `}
  &:hover ${CustomThumb} {
    opacity: 1;
  }
`;

const SuggestionsScrollbar: React.FC<IScrollbarDropdown> = ({ children }) => {
  return (
    <Container>
      <CustomScrollbar $maxHeight={236} $minHeight={80}>
        <Flex column px={5.5} pt={6.5} style={{ alignItems: 'flex-start' }}>
          {children}
        </Flex>
      </CustomScrollbar>
    </Container>
  );
};

export default SuggestionsScrollbar;
