import React from 'react';
import Icon, { IIcon } from './Icon';

const NewCartIcon: React.FC<IIcon> = props => (
  <Icon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <mask id="path-1-inside-1_12730_19766" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.38912 15.4241C3.89006 14.925 4.083 13.9229 4.82009 13.1858C5.55722 12.4487 6.55933 12.2558 7.05839 12.7548C7.55746 13.2539 7.36451 14.256 6.62738 14.9931C5.89029 15.7302 4.88819 15.9232 4.38912 15.4241Z"
      />
    </mask>
    <path
      d="M3.18703 16.6262C3.85092 17.2901 4.9273 17.2901 5.5912 16.6262C6.25509 15.9623 6.2551 14.8859 5.59121 14.222L3.18703 16.6262ZM7.05839 12.7548L8.26048 11.5527L8.26038 11.5526L7.05839 12.7548ZM5.59121 14.222C4.92732 13.5581 3.85094 13.5581 3.18705 14.222C2.52315 14.8859 2.52315 15.9623 3.18703 16.6262L5.59121 14.222ZM5.59121 14.222C5.7149 14.3457 5.77532 14.4839 5.79864 14.5849C5.82013 14.6779 5.80837 14.7261 5.81039 14.7156C5.81602 14.6864 5.86164 14.5484 6.02218 14.3879L3.618 11.9837C3.04145 12.5603 2.62206 13.292 2.47171 14.0728C2.32355 14.8423 2.41922 15.8584 3.18703 16.6262L5.59121 14.222ZM6.02218 14.3879C6.18272 14.2274 6.32066 14.1817 6.34992 14.1761C6.36039 14.1741 6.31216 14.1858 6.21918 14.1644C6.11824 14.1411 5.98011 14.0807 5.85641 13.957L8.26038 11.5526C7.49258 10.785 6.47657 10.6893 5.70712 10.8374C4.92627 10.9878 4.19459 11.4072 3.618 11.9837L6.02218 14.3879ZM5.85631 13.9569C5.73261 13.8332 5.67219 13.695 5.64887 13.5941C5.62738 13.501 5.63914 13.4528 5.63713 13.4633C5.63149 13.4925 5.58587 13.6305 5.42529 13.7911L7.82947 16.1952C8.40603 15.6186 8.82544 14.887 8.9758 14.1061C9.12396 13.3366 9.0283 12.3206 8.26048 11.5527L5.85631 13.9569ZM5.42529 13.7911C5.26476 13.9516 5.12681 13.9972 5.09757 14.0029C5.08711 14.0049 5.13536 13.9931 5.22838 14.0146C5.32936 14.0379 5.46751 14.0983 5.59121 14.222L3.18703 16.6262C3.95485 17.394 4.97094 17.4897 5.74042 17.3415C6.52127 17.1912 7.25292 16.7718 7.82947 16.1952L5.42529 13.7911Z"
      fill="currentColor"
      mask="url(#path-1-inside-1_12730_19766)"
    />
    <mask id="path-3-inside-2_12730_19766" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8369 15.4241C10.3378 14.925 10.5308 13.9229 11.2678 13.1858C12.005 12.4487 13.0071 12.2558 13.5061 12.7548C14.0052 13.2539 13.8123 14.256 13.0751 14.9931C12.338 15.7302 11.3359 15.9232 10.8369 15.4241Z"
      />
    </mask>
    <path
      d="M9.63479 16.6262C10.2987 17.2901 11.3751 17.2901 12.0389 16.6262C12.7028 15.9623 12.7029 14.8859 12.039 14.222L9.63479 16.6262ZM13.5061 12.7548L14.7082 11.5527L14.7081 11.5526L13.5061 12.7548ZM12.039 14.222C11.3751 13.5581 10.2987 13.5581 9.6348 14.222C8.97091 14.8859 8.9709 15.9623 9.63479 16.6262L12.039 14.222ZM12.039 14.222C12.1627 14.3457 12.2231 14.4839 12.2464 14.5849C12.2679 14.6779 12.2561 14.7261 12.2581 14.7156C12.2638 14.6864 12.3094 14.5484 12.4699 14.3879L10.0658 11.9837C9.48921 12.5603 9.06981 13.292 8.91946 14.0728C8.77131 14.8423 8.86698 15.8584 9.63479 16.6262L12.039 14.222ZM12.4699 14.3879C12.6305 14.2274 12.7684 14.1817 12.7977 14.1761C12.8081 14.1741 12.7599 14.1858 12.6669 14.1644C12.566 14.1411 12.4279 14.0807 12.3042 13.957L14.7081 11.5526C13.9403 10.785 12.9243 10.6893 12.1549 10.8374C11.374 10.9878 10.6423 11.4072 10.0658 11.9837L12.4699 14.3879ZM12.3041 13.9569C12.1804 13.8332 12.1199 13.695 12.0966 13.5941C12.0751 13.501 12.0869 13.4528 12.0849 13.4633C12.0792 13.4925 12.0336 13.6305 11.873 13.7911L14.2772 16.1952C14.8538 15.6186 15.2732 14.887 15.4236 14.1061C15.5717 13.3366 15.476 12.3206 14.7082 11.5527L12.3041 13.9569ZM11.873 13.7911C11.7125 13.9516 11.5746 13.9972 11.5453 14.0029C11.5349 14.0049 11.5831 13.9931 11.6761 14.0146C11.7771 14.0379 11.9153 14.0983 12.039 14.222L9.63479 16.6262C10.4026 17.394 11.4187 17.4897 12.1882 17.3415C12.969 17.1912 13.7007 16.7718 14.2772 16.1952L11.873 13.7911Z"
      fill="currentColor"
      mask="url(#path-3-inside-2_12730_19766)"
    />
    <path
      d="M2.5 2C3.03731 2.30704 4.91791 2.80597 4.91791 6.02985"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M15.191 5.22363H5.91797C5.36568 5.22363 4.91797 5.67135 4.91797 6.22363V9.46244C4.91797 10.0147 5.36568 10.4624 5.91797 10.4624H12.7626C13.1414 10.4624 13.4877 10.2484 13.657 9.90965L15.6383 5.94724C15.8045 5.61479 15.5627 5.22363 15.191 5.22363Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </Icon>
);

export default NewCartIcon;
