import React, { useCallback } from 'react';
import { IProduct } from 'Modules/Products/types';
import { sortStates, directSortStates } from 'constants/enums';
import { ICartCategory } from '../index';
import ProductsByCategories from 'Containers/ProductsByCategories';
import ProductsList, { IProductList } from '../../ProductsList';
interface IFilteredProductList {
  productsByCategories: ICartCategory[];
  products: IProduct[];
  sortState: sortStates;
  directSort: directSortStates;
  activeCategories?: number[];
}

const FilteredProductList: React.FC<IFilteredProductList> = ({
  products: allProducts,
  productsByCategories,
  sortState,
  directSort,
  activeCategories = [],
}: IFilteredProductList) => {
  let filteredProductList = [...allProducts].sort((a, b) => (a.available < b.available ? 1 : -1));

  if (activeCategories.length) {
    filteredProductList = filteredProductList.filter(
      (product: IProduct) => product.root_category_id && activeCategories.includes(product.root_category_id)
    );

    if (sortState === sortStates.byCategory) {
      productsByCategories = productsByCategories.filter((category: ICartCategory) =>
        activeCategories.includes(category.id)
      );
    }
  }

  if (directSort === directSortStates.asc) {
    filteredProductList = filteredProductList.reverse();
  }

  const renderProductsList = useCallback((params: IProductList) => <ProductsList {...params} />, []);

  return sortState === sortStates.byCategory ? (
    <ProductsByCategories inOrder productsByCategories={productsByCategories} renderProductsList={renderProductsList} />
  ) : (
    <ProductsList products={filteredProductList} inOrder />
  );
};

export default FilteredProductList;
