import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IAddressWithCoordinate } from 'Modules/SearchAddress/types';
import { getCurrentCityId } from 'Modules/SearchAddress/selectors';
import { EMPTY_ARRAY } from 'constants/constants';

export const ADDRESS_SEARCH_HISTORY = 'addressHistory';

const getAllAddresses = (): { [key in string]: IAddressWithCoordinate[] } | undefined => {
  const allAddresses = localStorage.getItem(ADDRESS_SEARCH_HISTORY);
  if (allAddresses) {
    return JSON.parse(allAddresses);
  }
};

const getAddressesByCity = (cityId: number): IAddressWithCoordinate[] => {
  const allAddresses = getAllAddresses();
  return allAddresses?.[cityId] || [];
};

export const addAddressToHistory = (cityId: number, address: IAddressWithCoordinate) => {
  const allAddresses = getAllAddresses();
  const addressesByCity = allAddresses?.[cityId] || [];
  if (!addressesByCity.find(item => item.name === address.name)) {
    addressesByCity.unshift(address);
  }
  localStorage.setItem(ADDRESS_SEARCH_HISTORY, JSON.stringify({ ...allAddresses, [cityId]: addressesByCity }));
};

export const resetHistory = () => {
  localStorage.removeItem(ADDRESS_SEARCH_HISTORY);
};

export const rmAddressFromHistory = (cityId: number, address: IAddressWithCoordinate) => {
  const allAddresses = getAllAddresses();
  const newAddressesByCity = allAddresses?.[cityId]?.filter(item => item.name !== address.name);
  localStorage.setItem(ADDRESS_SEARCH_HISTORY, JSON.stringify({ ...allAddresses, [cityId]: newAddressesByCity }));

  return newAddressesByCity || [];
};

export const useSearchHistoryAddresses = () => {
  const cityId = useSelector(getCurrentCityId);
  const [history, setHistory] = useState<IAddressWithCoordinate[]>(EMPTY_ARRAY);

  useEffect(() => {
    setHistory(getAddressesByCity(cityId));
  }, [cityId]);

  const clearHistory = () => {
    resetHistory();
    setHistory(EMPTY_ARRAY);
  };

  const removeAddressFromHistory = (address: IAddressWithCoordinate) => {
    setHistory(rmAddressFromHistory(cityId, address));
  };

  return {
    history,
    clearHistory,
    removeAddressFromHistory,
  };
};
