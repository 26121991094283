import React from 'react';
import { Flex } from '@igooods/ui-kit';
import { productColIndents } from './ProductCol';

// Временное решение для обновленной сетки, замена компоненту Row из ui-kit
// Позже перепишем на использование свойств из темы
const ProductsListContainer = ({
  mx = {
    xs: -1 * productColIndents.px.xs,
    sm: -1 * productColIndents.px.sm,
    lg: -1 * productColIndents.px.lg,
  },
  my = {
    xs: -1 * productColIndents.py.xs,
    lg: -1 * productColIndents.py.lg,
  },
  ...props
}) => <Flex mx={mx} my={my} {...props} wrap />;

export default ProductsListContainer;
