import React, { useContext } from 'react';
import { IProduct } from 'Modules/Products/types';
import ProductCardDesktop from 'Components/Product';
import { EnumFromName } from 'constants/enums';
import styled from 'styled-components';
import { mediaMdDown } from 'theme/helpers/css';
import ProductCol from 'Components/Products/ProductCol';
import ProductsContext from 'Components/Products/context';
import { equals } from 'ramda';
import { Box } from '@igooods/ui-kit';
import ProductsListContainer from 'Components/Products/ProductsListContainer';
import { IModelLimit } from 'Modules/Cart/types';

export interface IProductList {
  products: IProduct[];
  inOrder?: boolean;
  inCurrentOrder?: boolean;
  orderId?: number;
  testId?: string;
  limits?: IModelLimit[];
}

const ProductsBox = styled(Box)`
  ${mediaMdDown`
    margin: 0;
  `}
`;

const ProductsList: React.FC<IProductList> = ({ products, limits = [], inOrder, inCurrentOrder, orderId, testId }) => {
  const { analyticsIntersectionObserver } = useContext(ProductsContext);

  const calcIsAmountOverLimit = (modelId: number) =>
    Boolean(limits.find(({ model_ids }) => model_ids?.includes(modelId)));

  return (
    <ProductsBox mb={5} data-testid={testId}>
      <ProductsListContainer>
        {products.map((product: IProduct) => (
          <ProductCol key={product.id}>
            <ProductCardDesktop
              isAmountOverLimit={calcIsAmountOverLimit(product.model_id)}
              product={product}
              inOrder={inOrder}
              inCurrentOrder={inCurrentOrder}
              from={EnumFromName.CART}
              orderId={orderId}
              analyticsIntersectionObserver={analyticsIntersectionObserver}
            />
          </ProductCol>
        ))}
      </ProductsListContainer>
    </ProductsBox>
  );
};

export default React.memo(ProductsList, equals);
