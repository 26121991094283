import React, { FC } from 'react';
import Icon, { IIcon } from './Icon';

const TruckIcon: FC<IIcon> = () => (
  <Icon width="18" height="13" viewBox="0 0 18 13" fill="none">
    <path
      d="M11.5 4H14.29C14.4215 4 14.5446 4.06465 14.6193 4.17291L16.408 6.76665C16.467 6.8523 16.4904 6.95759 16.4731 7.06018L16.0553 9.54042C16.0228 9.73298 15.8561 9.87397 15.6608 9.87397H14.4131"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinejoin="round"
    />
    <path
      d="M7.418 9.88777L9.66281 9.87577C9.85717 9.87473 10.0227 9.73413 10.0551 9.54249L11.3619 1.81674C11.4341 1.38957 11.1049 1 10.6717 1H2.83809C2.64289 1 2.4762 1.14089 2.44368 1.33337L1.07894 9.41162C1.03767 9.65591 1.22615 9.87859 1.4739 9.87825L4.586 9.87397"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinejoin="round"
    />
    <path d="M9.85352 9.87305H11.5855" stroke="currentColor" strokeWidth="1.2" strokeLinejoin="round" />
    <path
      d="M4.26488 11.5152C3.7985 11.0571 3.97362 10.1373 4.65604 9.46068C5.3385 8.78406 6.26981 8.60703 6.73619 9.06505C7.20258 9.52315 7.02746 10.443 6.345 11.1196C5.66258 11.7962 4.73127 11.9733 4.26488 11.5152"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinejoin="round"
    />
    <path
      d="M11.5149 11.5152C11.0485 11.0571 11.2236 10.1373 11.906 9.46068C12.5885 8.78406 13.5198 8.60703 13.9862 9.06505C14.4526 9.52315 14.2775 10.443 13.595 11.1196C12.9126 11.7962 11.9813 11.9733 11.5149 11.5152"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinejoin="round"
    />
  </Icon>
);

export default TruckIcon;
