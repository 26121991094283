import { IApplicationState } from '../../reduxStore/types';
import { getFavoritesCategoriesState } from 'Modules/Categories/selectors';

export const getFavoritesState = (state: IApplicationState) => state.favorites;

export const getIsFavoritesIdle = (state: IApplicationState) => getFavoritesState(state).isIdle;

export const getFavoritesProductsState = (state: IApplicationState) => getFavoritesState(state).products;

export const getFavoritesProducts = (state: IApplicationState) => getFavoritesProductsState(state).list;

export const getFavoritesProductsTotal = (state: IApplicationState) => getFavoritesProductsState(state).total;

export const getFavoritesCurrentCategory = (state: IApplicationState, categoryId: string) => {
  const categories = getFavoritesCategoriesState(state);

  return categories.list.find(({ id }) => id === +categoryId);
};

export const getFavoritesCurrentCategoryFilters = (categoryId: string) => (state: IApplicationState) => {
  const category = getFavoritesCurrentCategory(state, categoryId);
  if (category) {
    return category.filters ? [category.filters] : category.filters;
  }
};

export const getFavoritesCurrentCategoryTitle = (categoryId: string) => (state: IApplicationState) => {
  const category = getFavoritesCurrentCategory(state, categoryId);

  if (category) {
    return category.name;
  }

  return '';
};

export const getFavoritesCurrentCategoryPriceRange = (categoryId: string) => (state: IApplicationState) => {
  const category = getFavoritesCurrentCategory(state, categoryId);
  if (category) {
    return category.price;
  }
};

export const getFavoritesAvailableProductsCount = (state: IApplicationState) =>
  getFavoritesProducts(state).filter(({ available }) => available).length;

export const getIsProductInFavoritesList = (productId: number) => (state: IApplicationState) => {
  const productList = getFavoritesProducts(state);

  return productList.some(({ id }) => id === productId);
};
