import { useRef } from 'react';
import { useIsomorphicLayoutEffect } from 'utils/hooks';

const useOptimizedResizeEffect = (effect: any, deps: any[]) => {
  const isRunning = useRef<boolean>(false);
  useIsomorphicLayoutEffect(() => {
    const resizeCallback = () => {
      if (!isRunning.current) {
        isRunning.current = true;
        requestAnimationFrame(() => {
          effect();
          isRunning.current = false;
        });
      }
    };

    window.addEventListener('resize', resizeCallback);
    return () => {
      window.removeEventListener('resize', resizeCallback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useOptimizedResizeEffect;
