import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { getProductCartParams } from '../../Modules/Cart/selectors';
import { EnumFromName } from 'constants/enums';
import { equals } from 'ramda';
import { IProduct } from '../../Modules/Products/types';
import ProductCard from 'Components/Product';
import { IAnalyticsParams } from 'utils/tagManager/types';

interface ICategoryProduct {
  product: IProduct;
  withoutAddToUserSet?: boolean;
  from: EnumFromName;
  productsSetId?: number;
  position: number;
  analyticsIntersectionObserver?: IntersectionObserver;
  analyticsParams?: IAnalyticsParams;
  onClickCard?: () => void;
}

const CategoryProduct: React.FC<ICategoryProduct> = ({ product, ...props }) => {
  const productPropsFromCart = useSelector(getProductCartParams(product.id), equals);

  return (
    <ProductCard
      product={{
        ...product,
        ...(productPropsFromCart ?? { amount: 0 }),
      }}
      {...props}
    />
  );
};

export default memo(CategoryProduct, equals);
