import React, { memo } from 'react';
import styled from 'styled-components';
import { mediaSmUp } from 'theme/helpers/css';
import { Row } from '@igooods/ui-kit';
import { ProductLoading } from 'Containers/Category/loading';

const Loading = styled(Row)`
  height: 280px;
  overflow: hidden;

  ${mediaSmUp`
    height: 316px;
  `}
`;

export const IMPULSE_GOODS_LOADING_TEST_ID = 'IMPULSE_GOODS_LOADING_TEST_ID';

const ProductsLoading = () => {
  return (
    <Loading data-testId={IMPULSE_GOODS_LOADING_TEST_ID}>
      <ProductLoading />
      <ProductLoading />
      <ProductLoading />
      <ProductLoading />
      <ProductLoading />
    </Loading>
  );
};

export default memo(ProductsLoading);
