import React, { FC } from 'react';
import Icon, { IIcon } from './Icon';

const EditIcon: FC<IIcon> = props => (
  <Icon width="13" height="13" viewBox="0 0 13 13" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.1398 11.2634L1 12L1.73664 8.86065L9.31131 1.28444C9.69103 0.905188 10.3063 0.905188 10.6855 1.28444L11.7159 2.31438C12.0947 2.69362 12.0947 3.30838 11.7159 3.68812L4.1398 11.2634Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10 5L8 3" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);

export default EditIcon;
