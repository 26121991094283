import { IGeoGrinderPlace } from 'Modules/GeoGrinder/types';
import React from 'react';
import { Box } from '@igooods/ui-kit';
import SuggestionsScrollbar from './SuggestionsScrollbar';
import { FontSize } from 'theme';
import HighlightText from 'Components/HighlightText';
import { Text } from 'Components/Typography';
import { colors } from 'constants/colors';
import { geoGrinderPlaceToAddressString } from 'Modules/GeoGrinder/utils';

const formatAddress = (place: IGeoGrinderPlace): { title: string; subtitle: string } => ({
  title: geoGrinderPlaceToAddressString(place),
  // может понадобиться в будущем
  subtitle: '',
});

interface ISuggestionsDropdownProps {
  suggestions: IGeoGrinderPlace[];
  show: boolean;
  onSelect: (suggestion: IGeoGrinderPlace) => void;
}

const SuggestionsDropdown: React.FC<ISuggestionsDropdownProps> = ({ suggestions, show, onSelect }) => {
  return suggestions.length && show ? (
    <SuggestionsScrollbar>
      {suggestions.map(suggestion => {
        const { title, subtitle } = formatAddress(suggestion);
        if (title) {
          return (
            <HighlightText
              key={suggestion.id || suggestion.full_address}
              size={FontSize.s}
              title={title}
              subtitle={
                <Box>
                  <Text size={FontSize.s} color={colors.dark100}>
                    {subtitle}
                  </Text>
                </Box>
              }
              onClick={() => onSelect(suggestion)}
            />
          );
        }
      })}
    </SuggestionsScrollbar>
  ) : null;
};

export default SuggestionsDropdown;
