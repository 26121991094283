import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'constants/colors';
import { Box } from '@igooods/ui-kit';
import Title from 'Components/Typography/Title';
import { FontFamily } from 'theme';
import { mediaSmDown, mediaCustomUp, mediaMdDown, mediaLandscape } from 'theme/helpers/css';
import { EnumButtonSize, EnumTheme } from 'constants/enums';
import CloseButtonIcon from 'Components/Icon/CloseButtonIcon';
import { HEADER_DROPDOWN_CLOSE_BTN_TEST_ID } from 'Components/Header/HeaderDropdown';
import ThemeButton from 'Components/ThemedButton';
import SearchAddressDesktop from 'Components/SearchAddress_new/Desktop';

const SearchAddressGrid = styled(Box).attrs({ mt: 8 })`
  grid-area: search;
  position: relative;
  background-color: ${colors.white};
  z-index: 2;
  ${mediaSmDown`
    display: none;
    ${mediaLandscape`
      display: flex;
    `}
  `}
`;

export const SATitle = styled(Title).attrs({
  ...{
    fontFamily: FontFamily.secondFont,
    color: colors.blackRussian,
  },
  level: '1',
})`
  ${mediaCustomUp(1920)`
    font-size: 38px;
    line-height: 46px;
  `}
`;

const CloseBtn = styled(ThemeButton).attrs({ icon: true })`
  position: absolute;
  top: 2px;
  right: 36px;
  max-width: 48px;

  ${mediaMdDown`
    top: 8px;
    right: 24px;
  `}
`;

interface ISearchDesktop {
  onSelectAddress: () => void;
  withCloseBtn?: boolean;
  onHideSelectAddress?: () => void;
  saveAddressChanges?: () => void;
}

const SearchDesktop: FC<ISearchDesktop> = ({ onSelectAddress, withCloseBtn, onHideSelectAddress }) => {
  return (
    <SearchAddressGrid>
      <SearchAddressDesktop autoFocus onSelectAddress={onSelectAddress} />
      {withCloseBtn && onHideSelectAddress && (
        <CloseBtn
          data-testid={HEADER_DROPDOWN_CLOSE_BTN_TEST_ID}
          onClick={onHideSelectAddress}
          use={EnumTheme.roundRed}
          size={EnumButtonSize.normal}
          icon
        >
          <CloseButtonIcon color={colors.white} />
        </CloseBtn>
      )}
    </SearchAddressGrid>
  );
};

export default SearchDesktop;
