import React, { useState } from 'react';
import styled from 'styled-components';
import iconClearCart from 'assets/images/cart/clear-cart.png';
import ThemeButton from 'Components/ThemedButton';
import { Modal, ModalHeader, ModalTitle, ModalSubTitle } from 'Containers/Modals/styled';
import { mediaSmDown } from 'theme/helpers/css';
import useModalState from 'utils/hooks/useModalState';
import CenteredClosableModal from 'Components/Modal/CenteredClosableModal';
import { ClearButton } from './styled';
import Box from 'Components/Grid/Box';
import Flex from 'Components/Grid/Flex';
import { EnumTheme } from 'constants/enums';
import { colors } from 'constants/colors';
import { FontFamily, FontWeight } from 'theme';
import TrashIcon from 'Components/Icon/TrashIcon';
interface IClearCartModalProps {
  onApprove: () => void;
}

export const MODAL_CLEAR_CART_TEST_ID = 'MODAL_CLEAR_CART_TEST_ID';
export const APPROVE_CLEAR_BTN_TEST_ID = 'APPROVE_CLEAR_BTN_TEST_ID';
export const CANCEL_CLEAR_BTN_TEST_ID = 'CANCEL_CLEAR_BTN_TEST_ID';
export const OPEN_CLEAR_MODAL_BTN_TEST_ID = 'CLEAR_BTN_TEST_ID';

const ImageClearCart = styled.div`
  width: 60px;
  height: 60px;
  background-size: contain;
  background-image: url('${iconClearCart}');
  margin: 0 auto;
`;

const Header = styled(ModalHeader)`
  margin-bottom: 78px;

  ${mediaSmDown`
    margin-bottom: 40px;
  `}
`;

const StyledIcon = styled(TrashIcon)`
  margin-right: 6px;
`;

const StyledText = styled.span`
  color: ${colors.dark300};
  font-family: ${FontFamily.mainFont};
  font-size: 14px;
  font-weight: ${FontWeight.normal};
`;

const ClearCartModal: React.FC<IClearCartModalProps> = ({ onApprove }) => {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { isVisible, showModal, hideModal } = useModalState();

  const handleApprove = () => {
    setLoadingBtn(true);
    onApprove();
  };

  return (
    <>
      <ClearButton onClick={showModal} data-testid={OPEN_CLEAR_MODAL_BTN_TEST_ID}>
        <Flex middle>
          <StyledIcon />
          <StyledText>Очистить</StyledText>
        </Flex>
      </ClearButton>
      <CenteredClosableModal show={isVisible} closeModal={hideModal}>
        <Modal data-testid={MODAL_CLEAR_CART_TEST_ID}>
          <Header>
            <ImageClearCart />
            <ModalTitle>Очистить корзину?</ModalTitle>
            <ModalSubTitle>Все товары будут удалены из корзины. Действие невозможно отменить</ModalSubTitle>
          </Header>
          <Box mtXs width="100%">
            <ThemeButton
              size="large"
              onClick={handleApprove}
              isLoading={loadingBtn}
              testId={APPROVE_CLEAR_BTN_TEST_ID}
              use={EnumTheme.roundRed}
              fluid
            >
              Очистить
            </ThemeButton>
          </Box>
          <Box mt={5} mtXs={2} width="100%">
            <ThemeButton
              use={EnumTheme.roundDarkGray}
              size="large"
              onClick={hideModal}
              fluid
              testId={CANCEL_CLEAR_BTN_TEST_ID}
            >
              Оставить как есть
            </ThemeButton>
          </Box>
        </Modal>
      </CenteredClosableModal>
    </>
  );
};

export default ClearCartModal;
