import React, { useEffect } from 'react';
import emojiTragic from 'assets/images/emoji-tragic.png';
import ThemeButton from 'Components/ThemedButton';
import { useSelector } from 'react-redux';
import { getCartState, getNotAvailableProducts } from 'Modules/Cart/selectors';
import missproductsViewAnalitic from 'utils/analitic/missproductsViewAnalitic';
import CenteredClosableModal from 'Components/Modal/CenteredClosableModal';
import styled from 'styled-components';
import { Modal, ModalHeader, ModalImage, ModalTitle } from 'Containers/Modals/styled';
import Box from 'Components/Grid/Box';
import { mediaSmDown } from 'theme/helpers/css';
import { EnumTheme } from 'constants/enums';
import ProductListCardShort from 'Components/Product/ListCardShort';

interface ICartRunsOutModalProps {
  isShow: boolean;
  onCancel: () => void;
  onApprove: () => void;
}

export const CART_RUNS_OUT_MODAL_TEST_ID = 'CART_RUNS_OUT_MODAL_TEST_ID';
export const APPROVE_RUNS_OUT_BTN_TEST_ID = 'APPROVE_RUNS_OUT_BTN_TEST_ID';
export const CANCEL_RUNS_OUT_BTN_TEST_ID = 'CANCEL_RUNS_OUT_BTN_TEST_ID';

const ModalProducts = styled.div`
  overflow: auto;
  max-height: 270px;
  margin: 32px 0px;

  ${mediaSmDown`
    max-height: none;
  `}
`;

const ModalHeaderStyled = styled(ModalHeader)`
  ${mediaSmDown`
    align-items: left;
  `}
`;

const CartRunsOutModal: React.FC<ICartRunsOutModalProps> = ({ isShow, onCancel, onApprove }) => {
  const products = useSelector(getNotAvailableProducts);
  const currentCart = useSelector(getCartState);

  useEffect(() => {
    if (isShow && currentCart) {
      missproductsViewAnalitic();
    }
  }, [currentCart, isShow]);

  return (
    <CenteredClosableModal closeModal={onCancel} show={isShow}>
      <Modal data-testid={CART_RUNS_OUT_MODAL_TEST_ID}>
        <ModalHeaderStyled>
          <ModalImage alt="" src={emojiTragic} />
          <ModalTitle center>Эти товары закончились</ModalTitle>
        </ModalHeaderStyled>
        <ModalProducts>
          {products.map(product => (
            <Box key={product.id} mb={3}>
              <ProductListCardShort product={{ ...product, available: true }} />
            </Box>
          ))}
        </ModalProducts>
        <Box mtXs width="100%">
          <ThemeButton
            size="large"
            use={EnumTheme.roundRed}
            fluid
            onClick={onApprove}
            data-testid={APPROVE_RUNS_OUT_BTN_TEST_ID}
          >
            Продолжить без этих товаров
          </ThemeButton>
        </Box>
        <Box mt={5} mtXs={2} width="100%">
          <ThemeButton
            use={EnumTheme.roundDarkGray}
            size="large"
            fluid
            onClick={onCancel}
            data-testid={CANCEL_RUNS_OUT_BTN_TEST_ID}
          >
            Остаться и заменить товары
          </ThemeButton>
        </Box>
      </Modal>
    </CenteredClosableModal>
  );
};

export default CartRunsOutModal;
