import { ThemeProvider } from 'styled-components';
import { EnumNotification, ProductStates, EnumGtagEvents, ModalType } from 'constants/enums';
import React, { memo, useCallback, useRef, useState, useEffect } from 'react';
import Flex from '../Grid/Flex';
import { EnumFromName } from 'constants/enums';
import CenteredClosableModal from 'Components/Modal/CenteredClosableModal';
import { CommentProductModal } from './CommentProductModal';
import NotificationAction from 'Modules/Notifications/actions';
import OrderActions, { changePendingProductsAddAction, changePendingProductsRemoveAction } from 'Modules/Cart/actions';
import { equals, isNil } from 'ramda';
import { getAnalyticsParams, getCurrentProductState, getProductCategoryUrl } from 'Components/Product/helpers';
import { removeProductFromOrder } from 'Modules/OrdersHistory/actions';
import { useDispatch, useSelector } from 'react-redux';
import useIntersectionObserveProduct from 'utils/hooks/useIntersectionObserveProduct';
import { getActiveOrdersInCurrentShop, getCartState } from 'Modules/Cart/selectors';
import { useHistory } from 'react-router';
import { themeCardByWL, themeCardByWLWithLimit } from './themes';
import AddToOrderModal from './ButtonToOrder/AddToOrderModal';
import useModalState from '../../utils/hooks/useModalState';
import { changeProductAmount } from '../../Modules/Product/actions';
import { IProductProps } from '../../Modules/Product/types';
import { EnumPictureShapes, EnumProductType } from '../../Modules/Products/types';
import Controls from './Controls';
import { REMOVE_BUTTON_TEST_ID, PRODUCT_COMMENT_BUTTON_TEST_ID, PRODUCT_COMMENT_WRAPPER_TEST_ID } from './constants';
import {
  GridCardStyled,
  ControlsWrapper,
  RemoveButtonStyled,
  CommentWrapper,
  ControlsRedButton,
  TopSlotContainer,
  AddToUserSetContainer,
  TopIconsGroup,
} from './styled';
import { Breakpoints } from 'theme';
import analyticsManager from 'utils/tagManager/manager';
import Badges from './Badges';
import PencilIcon from 'Components/Icon/PencilIcon';
import CheckIcon from 'Components/Icon/CheckIcon';
import { Box, ProductPrice } from '@igooods/ui-kit';
import LikeButton from './LikeButton';
import LinkWrapper from './LinkWrapper';
import AddToUserSetDropdown, { EnumUserSetsDropdownPosition } from 'Components/AddToUserSetDropdown';
import { useUserAgentContext } from 'utils/userAgent/context';
import { checkIsTempUser } from 'Modules/AAA/selectors';
import { isBrowser } from 'utils/helpers';
import { useWindowResize } from 'utils/hooks';
import { isKomus } from '../../../config/config';
import ModalActions from '../../Modules/Modals/actions';

export const SUCCESS_MESSAGE = 'Примечание добавлено';
export const SUCCESS_REMOVE_MESSAGE = 'Примечание удалено';

const ProductCard: React.FC<IProductProps> = (props: IProductProps) => {
  const {
    product,
    withoutAddToUserSet = false,
    inOrder = false,
    inCurrentOrder,
    from,
    from_product_page = false,
    productsSetId,
    orderId,
    analyticsIntersectionObserver,
    position,
    analyticsParams = {},
    onClickCard,
    isAmountOverLimit = false,
  } = props;

  const dispatch = useDispatch();

  const {
    isVisible: isCommentModalVisible,
    showModal: showCommentModal,
    hideModal: hideCommentModal,
  } = useModalState();
  const { isVisible: isOrderModalVisible, showModal: showOrderModal, hideModal: hideOrderModal } = useModalState();
  const history = useHistory();
  const { list: activeOrders } = useSelector(getActiveOrdersInCurrentShop);
  const [isMobileControlsVisible, setMobileControlsVisibility] = useState(false);
  const [dropDownPosition, setDropDownPosition] = useState<EnumUserSetsDropdownPosition>(
    EnumUserSetsDropdownPosition.CENTER
  );
  const currentCart = useSelector(getCartState);
  const { isMobile } = useUserAgentContext();
  const isTempUser = useSelector(checkIsTempUser);
  const openAuthModal = () => dispatch(ModalActions.openModal(ModalType.Auth));

  const {
    id,
    amount,
    available,
    comment,
    root_category_id,
    category_id,
    labels,
    item_weight,
    type,
    picture_shape,
  } = product;

  const productState = getCurrentProductState(amount, available, inOrder);
  const lastComment = useRef<string>(comment);
  const productHasComment = Boolean(comment);
  const isProductInCart = productState === ProductStates.inBasket;
  const isProductAvailable = productState !== ProductStates.notAvailable;
  const showMobilePrice = isProductAvailable && productState !== ProductStates.revert;
  const [optimisticAmout, setOptimisticAmount] = useState(product.amount);

  const analyticsRef = useRef<HTMLDivElement>(null);
  useIntersectionObserveProduct(analyticsIntersectionObserver, analyticsRef, product);

  const productCategoryUrl = getProductCategoryUrl(category_id, root_category_id);

  const analytics = getAnalyticsParams(analyticsParams, product);

  // eslint-disable-next-line
  const calculateDropdownPosition = () => {
    if (isBrowser) {
      const productListcontainer = document.getElementById('PRODUCT_LIST_CONTAINER');
      //в дашборде свой компонент для вывода 6 продуктов с дискаунтом
      const dashboardContainer = document.getElementById('DASHBOARD_CONTAINER');
      const container = productListcontainer || dashboardContainer;
      const productCard = document.getElementById(`${id}`);
      if (container && productCard) {
        const containerParameters = container.getBoundingClientRect();
        const cardParameters = productCard.getBoundingClientRect();
        const distanceBetweenLeft = cardParameters.left - containerParameters.left;
        distanceBetweenLeft <= 100 && setDropDownPosition(EnumUserSetsDropdownPosition.RIGHT);
      }
    }
  };

  useEffect(() => {
    if (optimisticAmout !== product.amount) {
      setOptimisticAmount(product.amount);
    }
    // eslint-disable-next-line
  }, [product.amount]);

  useEffect(() => {
    calculateDropdownPosition();
  }, [calculateDropdownPosition]);

  useWindowResize(() => calculateDropdownPosition(), []);

  const handleChangeAmount = (newAmount: number) => {
    setOptimisticAmount(newAmount);
    dispatch(changeProductAmount({ ...props, analyticsParams: analytics }, newAmount));
  };

  const handleAddToCart = () => {
    if (isTempUser) {
      openAuthModal();
    } else {
      // нужно, чтобы продукт добавлялся в pendingProducts сразу после нажатия кнопки
      dispatch(changePendingProductsAddAction([...currentCart.pendingProducts.add, id]));
      dispatch(OrderActions.checkIsNeedPassport(category_id));
      handleChangeAmount(1);
    }
  };

  const submitCommentByProduct = async (value: string | null) => {
    const newProductComment = !isNil(value) ? value : comment;
    if (!isNil(newProductComment) && newProductComment !== lastComment.current) {
      lastComment.current = newProductComment;
      await dispatch(OrderActions.addCommentToProduct(id, newProductComment, orderId));
      if (newProductComment.length) {
        await dispatch(NotificationAction.setNotificationAction(SUCCESS_MESSAGE, EnumNotification.success));
      } else {
        await dispatch(NotificationAction.setNotificationAction(SUCCESS_REMOVE_MESSAGE, EnumNotification.success));
      }
    }
  };

  const handleCommentFormSubmit = (value: string | null) => {
    if (!productHasComment) {
      const fromPage = from === EnumFromName.CART ? EnumFromName.CART : EnumFromName.CATALOG;
      analyticsManager.gtag(EnumGtagEvents.WRITE_PRODUCT_COMMENT, { from: fromPage });
    }
    submitCommentByProduct(value);
  };

  const handleRemoveProduct = useCallback(() => {
    if (inCurrentOrder) {
      dispatch(removeProductFromOrder({ product_id: id }));
    } else {
      // нужно, чтобы продукт добавлялся в pendingProducts сразу после нажатия кнопки
      dispatch(changePendingProductsRemoveAction([...currentCart.pendingProducts.remove, id]));
      dispatch(OrderActions.changeOrder({ product_id: id, amount: 0, weight: 0 }, product, from));
    }
  }, [currentCart.pendingProducts.remove, dispatch, from, id, inCurrentOrder, product]);

  const handleClickCard = () => {
    onClickCard && onClickCard();
  };

  const renderTopSlot = () => (
    <TopSlotContainer>
      <TopIconsGroup>
        <LikeButton product={product} from={from} />
        {!withoutAddToUserSet && !isMobile && !isKomus && (
          <AddToUserSetContainer>
            <AddToUserSetDropdown icon product={product} position={dropDownPosition} />
          </AddToUserSetContainer>
        )}
      </TopIconsGroup>

      <TopIconsGroup>
        {inOrder ? (
          <RemoveButtonStyled data-testid={REMOVE_BUTTON_TEST_ID} onClick={handleRemoveProduct} />
        ) : (
          <Badges labels={labels} />
        )}
      </TopIconsGroup>
    </TopSlotContainer>
  );

  return (
    <ThemeProvider theme={isAmountOverLimit ? themeCardByWLWithLimit : themeCardByWL}>
      <div
        ref={analyticsRef}
        data-id={id}
        data-pos={position}
        data-from={from}
        data-from-id={productsSetId}
        data-promotion-id={analytics?.promotionId}
        data-promotion-name={analytics?.promotionName}
        data-location-id={analytics?.promotionType}
        data-list-name={analytics?.listName}
        onClick={handleClickCard}
        id={`${id}`}
      >
        <GridCardStyled
          isKomus
          product={product}
          onAddToCart={handleAddToCart}
          onQuantityChange={handleChangeAmount}
          state={productState}
          onCommentFieldSubmit={handleCommentFormSubmit}
          inOrder={inOrder}
          onCommentMobileBtnSubmit={showCommentModal}
          onAddToOrder={() => {
            showOrderModal();
          }}
          showAddToOrder={activeOrders.length > 0}
          fullImage={picture_shape === EnumPictureShapes.wide}
          commentMaxLength={150}
          renderLinkWrapper={children => (
            <LinkWrapper product={product} options={{ product, from, productsSetId, analyticsParams }}>
              {children}
            </LinkWrapper>
          )}
          renderTopSlot={renderTopSlot}
          renderBottomSlot={() => (
            <Flex mt={{ xs: 1, sm: 2 }} grow column end>
              <Flex width="100%" between middle fluid wrap>
                {showMobilePrice && (
                  <Box
                    hideAfter={Breakpoints.sm}
                    width={isProductInCart ? '100%' : ''}
                    mbXs={isProductInCart ? 2.5 : 0}
                  >
                    <ProductPrice product={product} isProductInCart={isProductInCart} />
                  </Box>
                )}

                <ControlsWrapper isActive={isProductInCart} productState={productState}>
                  <Controls
                    state={productState}
                    weight={item_weight}
                    quantity={optimisticAmout || amount}
                    onAddToCart={handleAddToCart}
                    canAddToOrder={activeOrders.length > 0}
                    showWeight={type === EnumProductType.weight}
                    onAddToOrder={() => {
                      showOrderModal();
                    }}
                    isMobileControlsVisible={isMobileControlsVisible}
                    toggleMobileControls={(val: boolean) => {
                      setMobileControlsVisibility(val);
                    }}
                    onQuantityChange={handleChangeAmount}
                    onFindReplacement={() => history.push(productCategoryUrl)}
                    onRevert={handleAddToCart}
                    isAmountOverLimit={isAmountOverLimit}
                  />

                  {productState === ProductStates.inBasket && (
                    <CommentWrapper isActive={productHasComment} data-testid={PRODUCT_COMMENT_WRAPPER_TEST_ID}>
                      <ControlsRedButton
                        data-testid={PRODUCT_COMMENT_BUTTON_TEST_ID}
                        onClick={showCommentModal}
                        before={
                          productHasComment ? <CheckIcon color="currentColor" /> : <PencilIcon color="currentColor" />
                        }
                        fluid
                      >
                        Пожелание
                      </ControlsRedButton>
                    </CommentWrapper>
                  )}
                </ControlsWrapper>
              </Flex>
            </Flex>
          )}
        />
      </div>
      <CenteredClosableModal show={isOrderModalVisible} closeModal={hideOrderModal}>
        <AddToOrderModal
          {...product}
          from={from}
          from_product_page={from_product_page}
          productForAnalitic={product}
          closeModal={hideOrderModal}
          orders={activeOrders}
          analyticsParams={analytics}
        />
      </CenteredClosableModal>
      <CommentProductModal
        comment={comment}
        onSubmit={handleCommentFormSubmit}
        product={product}
        externalVisible={isCommentModalVisible}
        externalHide={hideCommentModal}
        external
      />
    </ThemeProvider>
  );
};

export default memo(ProductCard, equals);
