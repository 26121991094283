import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ICategory } from 'Modules/Categories/types';
import { IProduct } from 'Modules/Products/types';
import { directSortStates, EnumNotification, sortStates } from 'constants/enums';
import Flex from 'Components/Grid/Flex';
import { floatToNumberThousandSeparator, isBrowser } from 'utils/helpers';
import {
  getCartState,
  getNotAvailableProducts,
  getNotEnoughProductsPrice,
  isReadyCartForCheckout,
} from 'Modules/Cart/selectors';
import Check from 'Components/Check';
import CartGuideModal from '../CartGuideModal';
import { FbqEventsEnum, fbqTrack } from 'utils/fbq';
import { useHistory } from 'react-router-dom';
import { URL } from 'constants/urlMaps';
import NotificationAction from 'Modules/Notifications/actions';
import CartRunsOutModal from 'Containers/Cart/CartRunsOutModal/index';
import useModalState from 'utils/hooks/useModalState';
import OrderActions from 'Modules/Cart/actions';
import ShopsHttp from 'Modules/Shops/http';
import styled from 'styled-components';
import { getCurrentShopBrandLabel, getCurrentShopId } from 'Modules/Shops/selectors';
import { CartContainer, CartContent, MobileCartFooter, NotificationWrapper, SubmitMobileButton } from '../styled';
import Text from 'Components/Typography/Text';
import { FontSize } from 'theme';
import ImpulseGoods from './ImpulseGoods';
import CartControls from '../CartControls';
import CartNotification from './CartNotification';
import SoldOutProductsDelete from './SoldOutProductsDelete';
import LimitsPopup from 'Components/Popups/LimitsPopup';
import { getLimitsWithProductCount, sortProductsByCategories } from '../utils';
import FilteredProductList from 'Containers/Cart/Full/FilteredProductList';
import FilterSorting from 'Containers/Cart/Full/FilterSorting';

import { colors } from 'constants/colors';
import { NBSP, RUB } from 'constants/constants';
import limitationsImageSrc from 'assets/images/limitations_pic.png';
import pharmacyImageSrc from 'assets/images/pharmacy_pic.png';
import { mediaSmDown } from '../../../theme/helpers/css';
import { IS_KOMUS } from '../../../../config/config';

export const FULL_CART_TEST_ID = 'FULL_CART_TEST_ID';
export const CHECKOUT_BTN_TEST_ID = 'CHECKOUT_BTN_TEST_ID';
export const CLEAR_CART_ERR_MSG = 'Не удалось очистить корзину';
export const CLOSED_SHOP_ERR_NOTIFICATION_TEXT =
  'Магазин временно не доставляет по вашему адресу. Пожалуйста, смените магазин';
export const CLOSED_SHOP_ERR_TEXT = 'Магазин не найден';
export const SHOP_ERR_NOTIFICATION_TEXT = 'У нас что-то сломалось, уже чиним. Иногда помогает обновить страницу';

interface IFullCart {
  categories: ICategory[];
}

export interface ICartCategory {
  id: number;
  active: boolean;
  name: string;
  totalPrice: number;
  products: IProduct[];
}

const ArrowIcon = styled.span`
  font-size: 21px;
  padding-bottom: 2px;
  padding-right: 10px;
`;

const UnavailableChip = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: ${colors.blackRussian};
  color: ${colors.white};
  font-size: 13px;
  border-radius: 25px;
  width: 201px;
  height: 46px;
  bottom: 60px;
  left: calc(50% - 100px);
  cursor: pointer;
  z-index: 2000;

  ${mediaSmDown`
    width: 181px;
    height: 35px;
    top: 95px;
    left: calc(50% - 90px);
   `}
`;

const FullCart: React.FC<IFullCart> = ({ categories }: IFullCart) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const anchorRef = useRef(null);
  const isReadyToCheckout = useSelector(isReadyCartForCheckout);
  const notAvailableProducts = useSelector(getNotAvailableProducts);
  const currentCart = useSelector(getCartState);
  const currentShopId = useSelector(getCurrentShopId);
  const notEnoughProductsPrice = useSelector(getNotEnoughProductsPrice);
  const currentShopBrandLabel = useSelector(getCurrentShopBrandLabel);
  const [isLoading, setIsLoading] = useState(false);
  const [sortState, setSortState] = useState<sortStates>(sortStates.byAdding);
  const [directSort, setDirectSort] = useState<directSortStates>(directSortStates.asc);
  const [isShowChip, setIsShowChip] = useState(true);
  const [isAnimationNeeded, setIsAnimationNeeded] = useState(false);
  const isNotEnoughProductPrice = notEnoughProductsPrice > 0;

  const { products } = currentCart;
  const soldOutProducts = products.filter(product => product.available === false);
  const soldOutProductsLength = soldOutProducts.length;
  const submitButtonText = isNotEnoughProductPrice
    ? `Добавьте еще на ${floatToNumberThousandSeparator(notEnoughProductsPrice)} ${RUB}`
    : Boolean(soldOutProductsLength)
    ? 'Удалите раскупленное'
    : 'К оформлению заказа';

  const isPharmacyInCart = products.find(product => product?.pharmacy);

  const { isVisible: isLimitsPopupVisible, showModal: showLimitsPopup, hideModal: hideLimitsPopup } = useModalState();

  const {
    isVisible: isCartRunsOutModalShow,
    showModal: showCartRunsOutModal,
    hideModal: hideCartRunsModal,
  } = useModalState();

  const callback = () => {
    setIsShowChip(prev => !prev);
  };

  const handleAnchorClick = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    anchorRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  };

  useEffect(() => {
    if (Boolean(soldOutProductsLength)) handleAnchorClick();
    if (isBrowser) {
      const options = {
        threshold: 1.0, // 1 – полная видимость элемента, 0.5 – половина и т.д.
      };
      const observer = new IntersectionObserver(callback, options);
      const target = document.getElementById('unavailableDeleteContainer');
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      Boolean(target) && observer.observe(target);
    }
  }, [soldOutProductsLength]);

  const productsByCategories = sortProductsByCategories(products, categories);

  const triggerAnimation = () => {
    if (!isAnimationNeeded) {
      handleAnchorClick();
      setTimeout(() => {
        setIsAnimationNeeded(prevState => !prevState);
      }, 0);
      setTimeout(() => {
        setIsAnimationNeeded(false);
      }, 1000);
    }
  };

  const goToCheckout = async () => {
    try {
      setIsLoading(true);
      const data = await ShopsHttp.getShop(
        currentShopId,
        currentShopBrandLabel === 'utkonos' ? currentShopBrandLabel : ''
      );
      const isReadyAndNotEmpty = isReadyToCheckout && notAvailableProducts.length;

      if (!data.shop.open) {
        setIsLoading(false);
        return dispatch(NotificationAction.setNotificationAction(CLOSED_SHOP_ERR_NOTIFICATION_TEXT));
      }

      if (isReadyAndNotEmpty) {
        showCartRunsOutModal();
      } else if (isReadyToCheckout) {
        fbqTrack(FbqEventsEnum.InitiateCheckout);
        history.push(URL.checkout);
      }

      setIsLoading(false);
    } catch (e) {
      dispatch(NotificationAction.setNotificationAction(SHOP_ERR_NOTIFICATION_TEXT));
      setIsLoading(false);
    }
  };

  const handleCheckoutOrder = async () => {
    if (Boolean(soldOutProductsLength)) {
      triggerAnimation();
    } else {
      await goToCheckout();
    }
  };

  const handleGoToCheckout = () => history.push(URL.checkout);

  const handleClearCart = async () => {
    try {
      await dispatch(OrderActions.clearOrder());
    } catch (e) {
      dispatch(NotificationAction.setNotificationAction(CLEAR_CART_ERR_MSG, EnumNotification.danger));
    }
  };

  const rightWordEnding = (number: number, txt: string[], cases = [2, 0, 1, 1, 1, 2]) =>
    txt[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];

  const chipText = `  ${soldOutProductsLength} ${rightWordEnding(soldOutProductsLength, [
    'товар',
    'товара',
    'товаров',
  ])} раскупили`;

  const limitsWithProductCount = useMemo(() => getLimitsWithProductCount(currentCart), [currentCart]);

  return (
    <CartContainer data-testid={FULL_CART_TEST_ID}>
      <CartControls handleClearCart={handleClearCart} />
      <CartContent>
        <NotificationWrapper>
          {Boolean(currentCart.model_limits.length) && (
            <CartNotification
              backgroundColor={colors.sweetCorn}
              imageSrc={limitationsImageSrc}
              text={`Некоторые товары из вашей корзины нельзя купить про${NBSP}запас`}
              isQuestion
              onQuestionClick={showLimitsPopup}
            />
          )}
          {isPharmacyInCart && (
            <CartNotification
              backgroundColor={colors.ghostWhite}
              imageSrc={pharmacyImageSrc}
              text={`Товары из аптеки можно купить только в мобильном приложении`}
            />
          )}
        </NotificationWrapper>

        <FilterSorting
          {...{
            setSortState,
            setDirectSort,
          }}
        />

        {Boolean(soldOutProductsLength) && (
          <span ref={anchorRef}>
            <SoldOutProductsDelete isAnimationNeeded={isAnimationNeeded} />
          </span>
        )}

        <FilteredProductList
          {...{
            products,
            productsByCategories,
            directSort,
            sortState,
          }}
        />
        {!IS_KOMUS && <ImpulseGoods />}
      </CartContent>

      <Check
        isCheckoutChild
        isLoading={isLoading}
        isReadyToCheckout={isReadyToCheckout}
        onCheckoutOrder={handleCheckoutOrder}
        isHaveSoldoutProducts={Boolean(soldOutProductsLength)}
        conditions
      />

      {Boolean(soldOutProductsLength) && isShowChip && isBrowser && !history.location.search && (
        <UnavailableChip onClick={handleAnchorClick}>
          <ArrowIcon>&#8593;</ArrowIcon>
          {chipText}
        </UnavailableChip>
      )}

      <MobileCartFooter>
        <SubmitMobileButton
          onClick={handleCheckoutOrder}
          disabled={!isReadyToCheckout && !Boolean(soldOutProductsLength)}
          isLoading={isLoading}
          data-testid={CHECKOUT_BTN_TEST_ID}
          isHaveSoldoutProducts={Boolean(soldOutProductsLength)}
        >
          <Text size={FontSize.m}>{submitButtonText}</Text>
        </SubmitMobileButton>
      </MobileCartFooter>
      <CartGuideModal />
      <CartRunsOutModal isShow={isCartRunsOutModalShow} onCancel={hideCartRunsModal} onApprove={handleGoToCheckout} />
      <LimitsPopup show={isLimitsPopupVisible} limits={limitsWithProductCount} close={hideLimitsPopup} />
    </CartContainer>
  );
};

export default FullCart;
