import React from 'react';
import { IShopForPlace } from 'Modules/Shops/types';
import { Box, Flex } from '@igooods/ui-kit';
import { FontFamily, FontSize, FontWeight } from 'theme';
import { timeFormatted } from 'utils/date';
import styled from 'styled-components';
import { colors } from 'constants/colors';
import TruckIcon from 'Components/Icon/TruckIcon';
import { addStyleIfPropTruly } from 'utils/styledComponents';
import { getTypographyFontSize } from 'theme/selectors';
import CircleCheck from 'assets/images/circleCheck.svg';
import { mediaSmUp } from 'theme/helpers/css';
import { shortRelativeDate } from 'utils/date';
import { NBSP } from 'constants/constants';
import { Transitions } from 'theme';
import { highlightStyles } from 'Components/HighlightText';
import { EnumPaymentTypes } from '../../../constants/enums';
import { PaymentTypesText } from '../../../Components/ChangeShop/styled';

export const SELECT_SHOP_CARD_TEST_ID = 'SELECT_SHOP_CARD_TEST_ID';

const ShopName = styled.div`
  font-family: ${FontFamily.mainFont};
  ${getTypographyFontSize(FontSize.m)};
  font-weight: ${FontWeight.medium};
  line-height: 24px;
  transition: background-image 0.3s ease;
  padding: 0 2px;
`;

const ShopLogo = styled(Box).attrs({ width: '56px;' })<{ $logo: string }>`
  ${({ $logo }) => `background-image: url(${$logo});`}
  height: 56px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  flex: 0 0 56px;
  position: relative;
`;

const Container = styled(Flex)<{ $selected?: boolean; $isDisabled: boolean }>`
  cursor: pointer;
  position: relative;
  min-width: 220px;
  ${addStyleIfPropTruly('$isDisabled', 'filter: grayscale(1); opacity: 0.6;')}
  ${addStyleIfPropTruly(
    '$selected',
    `
      &:before {
        content: ' ';
        display: block;
        width: 18px;
        height: 18px;
        background-image: url(${CircleCheck});
        position: absolute;
        top: -1px;
        left: -1px;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 2;
      }
    `
  )}

  ${ShopLogo} {
    transition: ${Transitions.transform};
  }

  &:hover {
    ${mediaSmUp`
      ${ShopName} {
        ${highlightStyles}
      }

      ${ShopLogo} {
        transform: scale(1.05);
      }
  `}
  }
`;

const Day = styled.span`
  text-transform: capitalize;
`;

const Time = styled.div<{ $color: string }>`
  font-family: ${FontFamily.mainFont};
  ${({ $color }) => `color: ${$color};`}
  ${getTypographyFontSize(FontSize.s)}
`;

const Price = styled(Box)`
  color: ${colors.warmGrey};
  ${getTypographyFontSize(FontSize.xs)}
`;

interface IShopCard {
  shop: IShopForPlace;
  selected?: boolean;
  onSelectShop: (shop: IShopForPlace) => void;
}

const ShopCard: React.FC<IShopCard> = ({ shop, onSelectShop, selected }) => {
  const {
    group,
    zone: { delivery_date },
    logo: { round_logo_path },
  } = shop;

  const handlerSelectShop = () => {
    onSelectShop(shop);
  };

  const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

  const getPaymentTypes = () => {
    if (shop.payment_types) {
      const paymentsArray = ['СБП', 'картой онлайн', 'наличными', 'картой курьеру', 'постоплата'];

      const customSort = (responseArray: string[], sortedArray: string[]) => {
        return responseArray.sort(function (x1: string, x2: string) {
          const i1 = sortedArray.indexOf(x1);
          const i2 = sortedArray.indexOf(x2);
          return i1 < 0 ? 1 : i2 < 0 ? -1 : i1 - i2;
        });
      };

      const paymentTypesString = Object.entries(shop.payment_types)
        .filter(shop => Boolean(shop[1]) || shop[0] === 'shipment_on_credit')
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        .map(paymentType => EnumPaymentTypes[paymentType[0]]);

      const result = customSort(paymentTypesString, paymentsArray).join(', ');

      return capitalize(result);
    }
  };

  const deliveryDate = delivery_date?.date;

  return (
    <Container
      onClick={handlerSelectShop}
      data-testid={SELECT_SHOP_CARD_TEST_ID}
      $selected={selected}
      $isDisabled={!deliveryDate}
    >
      <ShopLogo mr={5} width="56px" $logo={round_logo_path} />
      <Flex column top>
        <ShopName>{group}</ShopName>
        <Box mt={1}>
          <Time $color={deliveryDate ? colors.dark300 : colors.warmGrey}>
            {deliveryDate ? (
              <Flex middle>
                <Box mr={2}>
                  <TruckIcon />
                </Box>
                <Day>{shortRelativeDate(deliveryDate)}</Day>
                {`${NBSP}c ${timeFormatted(deliveryDate)}`}
              </Flex>
            ) : (
              'Временно не доставляем'
            )}
          </Time>
          {!deliveryDate && <Price mt={1}>Но скоро будем</Price>}
        </Box>
        <PaymentTypesText>{getPaymentTypes()}</PaymentTypesText>
      </Flex>
    </Container>
  );
};

export default ShopCard;
