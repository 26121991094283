import { AddressComponentsEnum, IAddressComponent, IGeoGrinderPlace } from 'Modules/GeoGrinder/types';

export const geoGrinderPlaceToAddressString = (place: IGeoGrinderPlace): string => {
  const province = place.components.find(({ type }) => type === AddressComponentsEnum.PROVINCE)?.name;
  const locality = place.components.find(
    ({ type, name }) => type === AddressComponentsEnum.LOCALITY && name !== province
  )?.name;
  const street = place.components.find(({ type }) => type === AddressComponentsEnum.STREET)?.name;
  const building = place.components.find(({ type }) => type === AddressComponentsEnum.BUILDING)?.name;

  return `${locality ? `${street ? `${locality}, ${street}` : locality}` : `${street ? `${street}` : ''}`}${
    building ? `, ${building}` : ''
  }`;
};

export const getAddressObjFromGeogrinderComponents = (address_components: IAddressComponent[]) => {
  const entries = new Map(
    address_components.map(({ type, name }) => {
      return [type, name];
    })
  );

  return Object.fromEntries(entries);
};

export const getAddressNameFromGeogrinderComponents = ({ locality, street, building }: { [k: string]: string }) =>
  `${locality ? `${street ? `${locality}, ${street}` : locality}` : ''}${locality ? '' : street ?? ''}${
    building ? `, ${building}` : ''
  }`;
