import React from 'react';
import useFavoriteHandler from 'utils/hooks/useFavoriteHandler';
import { useLocation } from 'react-router';
import { IProduct } from 'Modules/Products/types';
import { EnumFromName } from 'constants/enums';
import { colors } from 'constants/colors';
import { StyledLikeIcon } from './styled';

export const LIKE_BUTTON_TEST_ID = 'LIKE_BUTTON_TEST_ID';

interface ILikeButtonProps {
  product: IProduct;
  from: EnumFromName;
  from_product_page?: boolean;
  outline?: boolean;
  className?: string;
}

const LikeButton = ({ product, outline = false, className, ...props }: ILikeButtonProps) => {
  const location = useLocation();
  const isFavoritesPage = location.pathname.includes('favorites');
  const favoritesId = () => {
    if (isFavoritesPage) {
      const currentFavoriteId = location.pathname.split('/')[3];
      if (Boolean(currentFavoriteId)) return currentFavoriteId;
    }
  };

  const currentFavoriteId = favoritesId();

  const { isFavoriteLocal, handleFavoriteClick } = useFavoriteHandler(product, props, currentFavoriteId);

  return (
    <div className={className} data-testid={LIKE_BUTTON_TEST_ID}>
      <StyledLikeIcon
        onClick={handleFavoriteClick}
        color={isFavoriteLocal ? colors.primaryMain : colors.white}
        $outline={outline}
        $isFavoriteLocal={isFavoriteLocal}
      />
    </div>
  );
};

export default LikeButton;
