import React, { useEffect, useRef, useState, forwardRef, useCallback, RefObject } from 'react';
import Dots from './Dots';
import Flickity from 'react-flickity-component';
import styled, { css } from 'styled-components';
import useOptimizedResizeEffect from 'utils/hooks/useOptimizedResizeEffect';
import { colors } from 'constants/colors';
import prevArrow from './img/prevArrow.svg';
import nextArrow from './img/nextArrow.svg';
import { addStyleIfPropTruly } from 'utils/styledComponents';
import { isServer } from 'utils/helpers';
import { Transitions } from 'theme';
import { mediaSmDown, mediaMdDown } from 'theme/helpers/css';
import { AUTOPLAY_DURATION } from './contants';

const arrowBtnStyles = `
      content: ' ';
      width: 8px;
      height: 13px;
      background-repeat: no-repeat;
      background-size: contain;
`;

const simpleArrowBtnStyles = `
  font-size: 22px;
  color: ${colors.greyish};
  transition: ${Transitions.color};

  &:hover {
    color: ${colors.greyishBrown};
  }
`;

const transparentArrowBtnStyles = `
  font-size: 23px;
  color: ${colors.white};
`;

const topRoundArrowBtnStyles = `
  transform: translateY(-1.5px);
  font-size: 23px;
`;

export const StyledDots = styled(Dots)``;

export enum EnumArrowType {
  roundGreen = 'roundGreen',
  simple = 'simple',
  transparent = 'transparent',
  topRound = 'topRound',
}

export const Wrapper = styled.div<{ $arrowType: EnumArrowType }>`
  position: relative;

  .flickity-prev-next-button {
    height: 50px;
    width: 50px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .flickity-button-icon {
    display: none;
  }

  .previous {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .next {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  ${({ $arrowType }) => {
    switch ($arrowType) {
      case EnumArrowType.roundGreen:
        return css`
          .flickity-prev-next-button {
            background-color: ${colors.lightGreyGreenFive};

            &:hover {
              background-color: ${colors.lightGreyGreenThree};
            }
          }

          .previous {
            transform: translateX(-50%);

            &:after {
              ${arrowBtnStyles}
              background-image: url(${prevArrow});
            }
          }

          .next {
            transform: translateX(50%);

            &:after {
              ${arrowBtnStyles}
              background-image: url(${nextArrow});
            }
          }
        `;
      case EnumArrowType.simple:
        return css`
          .flickity-prev-next-button {
            background-color: transparent;
          }

          .previous:after {
            content: '←';
            padding-bottom: 2px;
            ${simpleArrowBtnStyles}
          }

          .next:after {
            content: '→';
            padding-bottom: 2px;
            ${simpleArrowBtnStyles}
          }
        `;

      case EnumArrowType.transparent:
        return css`
          .flickity-prev-next-button {
            background-color: transparent;
            width: 58px;
            height: 58px;
            border: 1px solid rgba(255, 255, 255, 0.4);
            border-radius: 50%;
          }

          .previous:after {
            content: '←';
            padding-bottom: 2px;
            ${transparentArrowBtnStyles}
          }

          .next:after {
            content: '→';
            padding-bottom: 2px;
            ${transparentArrowBtnStyles}
          }
        `;

      case EnumArrowType.topRound:
        return css`
          .flickity-prev-next-button {
            width: 38px;
            height: 38px;
            border: 1px solid ${colors.paleSurfCrest};
            background-color: ${colors.white};
            border-radius: 50%;

            &:hover {
              border-color: ${colors.surfCrest};
              transition: border-color 200ms ease-out;
            }
          }

          .previous {
            top: -22px;
            left: calc(100% - 28px);
            transform: translate(-200%, -100%);

            &:disabled {
              color: ${colors.lightGray};
            }

            &:after {
              content: '←';
              padding-bottom: 2px;
              ${topRoundArrowBtnStyles}
            }
          }

          .next {
            top: -22px;
            right: 0;
            transform: translateY(-100%);

            &:disabled {
              color: ${colors.lightGray};
            }

            &:after {
              content: '→';
              padding-bottom: 2px;
              ${topRoundArrowBtnStyles}
            }
          }
        `;
    }
  }}

  ${mediaSmDown`
    ${({ $arrowType }: { $arrowType: EnumArrowType }) =>
      $arrowType === EnumArrowType.roundGreen &&
      `
      .flickity-prev-next-button {
        display: none;
      }
    `}
  `}

  ${mediaMdDown`
    ${({ $arrowType }: { $arrowType: EnumArrowType }) => {
      switch ($arrowType) {
        case EnumArrowType.transparent:
          return `
            .flickity-prev-next-button {
              width: 48px;
              height: 48px;
            }
          `;

        case EnumArrowType.topRound:
          return `
            .flickity-prev-next-button {
              display: none;
            }
          `;
      }
    }}
  `}
`;

export const FlickityStyled = styled(Flickity)`
  outline: none;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-user-select: none;
  -webkit-user-drag: none;
`;

// это чтобы потом доработать остановку при хавере
export const SliderWrapper = styled.div<{ static: boolean }>`
  overflow: hidden;

  ${FlickityStyled} {
    ${addStyleIfPropTruly('static', 'display: flex;')}
  }
`;

interface ISlider {
  children: React.ReactChild | React.ReactNode;
  testId?: string;
  className?: string;
  withDots?: boolean;
  withArrows?: boolean;
  infinite?: boolean;
  contain?: boolean;
  currentSlide?: number;
  arrowType?: EnumArrowType;
  resize?: boolean;
  autoPlay?: boolean;
  groupCells?: number;
  wrapAround?: boolean;
  draggable?: boolean;
}

const Slider = forwardRef<Flickity, ISlider>(
  (
    {
      children,
      testId,
      className,
      withDots,
      withArrows,
      infinite,
      contain,
      currentSlide = 0,
      resize = false,
      arrowType = EnumArrowType.roundGreen,
      autoPlay,
      groupCells,
      wrapAround = true,
      draggable = true,
    },
    ref
  ) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const localRef = useRef<Flickity>(null);
    const flickityRef = (ref as RefObject<Flickity>) || localRef;
    const [isDraggable, setDraggable] = useState(draggable);
    const [staticRender, setStatic] = useState(true);
    const [reloadOnUpdate, setReloadOnUpdate] = useState(true);

    const togglePlayState = (e: React.MouseEvent) => {
      if (isDraggable) {
        if (e.type === 'mouseenter') flickityRef.current?.pausePlayer();
        else flickityRef.current?.unpausePlayer();
      }
    };

    const checkDragability = useCallback(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (flickityRef?.current?.slideableWidth && draggable) {
        // подпираем костылём, чтобы пользователь не елозил слайдами когда не нужно
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (!autoPlay && flickityRef.current.size.width < flickityRef.current.slideableWidth) {
          setDraggable(true);
          flickityRef.current.playPlayer();
        } else {
          setDraggable(false);
          flickityRef.current.stopPlayer();
        }
      }

      // костыль для синхронизации анимации прогресса и паузы автоплея слайдера
      // нужно для паузы/воспроизведения на onMouseEnter/onMouseLeave
      ['play', 'stop', 'pause', 'unpause'].forEach(function (action) {
        const methodName = action + 'Player';
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (flickityRef.current?.__proto__) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          flickityRef.current.__proto__[methodName] = function () {
            this.player[action]();
            this.dispatchEvent(methodName);
          };
        }
      });
    }, [autoPlay, draggable, flickityRef]);

    useEffect(() => {
      /**
       * дополнительный костыль: на фронте и на сервере приходят рандомные слайды -> на фронте приходят другие рандомные слайды -> слайдер ловит гуся
       * эти 2 строчки при изменении children перерисовывают слайдер
       */
      flickityRef.current?.reloadCells();
      flickityRef.current?.resize();
    }, [children, flickityRef]);

    useEffect(() => {
      flickityRef?.current?.on('ready', () => {
        checkDragability();
        setStatic(false);
      });
      flickityRef.current?.on('dragStart', (e: PointerEvent | TouchEvent) => e.stopPropagation());
      flickityRef.current?.on('dragEnd', (e: PointerEvent | TouchEvent) => {
        e.stopPropagation();
        e.preventDefault();
      });
      flickityRef.current?.on('change', () => {
        setReloadOnUpdate(false);
      });
    }, [checkDragability, flickityRef]);

    useOptimizedResizeEffect(() => {
      flickityRef.current?.resize();
      checkDragability();
    }, [flickityRef]);

    return (
      <Wrapper data-testid={testId} ref={wrapperRef} className={className} $arrowType={arrowType}>
        <SliderWrapper static={staticRender} onMouseEnter={togglePlayState} onMouseLeave={togglePlayState}>
          <FlickityStyled
            flickityRef={c => {
              // flickity принимает в реф только функцию
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              flickityRef.current = c;
            }}
            static={isServer}
            reloadOnUpdate={reloadOnUpdate}
            disableImagesLoaded
            options={{
              cellAlign: 'left',
              resize: resize,
              pageDots: false,
              prevNextButtons: withArrows,
              draggable: isDraggable,
              wrapAround: infinite && wrapAround,
              dragThreshold: 1,
              initialIndex: currentSlide,
              contain,
              autoPlay: autoPlay && isDraggable && AUTOPLAY_DURATION,
              groupCells: groupCells,
              imagesLoaded: true,
            }}
          >
            {children}
          </FlickityStyled>
        </SliderWrapper>
        {withDots && (staticRender || isDraggable) && <StyledDots flickRef={flickityRef} />}
      </Wrapper>
    );
  }
);

Slider.displayName = 'Slider';

export default Slider;
