import React, { useEffect, useState } from 'react';
import cartNote from 'assets/images/cart/cart-note.svg';
import ThemeButton from 'Components/ThemedButton';
import Box from 'Components/Grid/Box';
import Title from 'Components/Typography/Title';
import Text from 'Components/Typography/Text';
import CenteredClosableModal from 'Components/Modal/CenteredClosableModal';
import styled from 'styled-components';
import Flex from 'Components/Grid/Flex';
import { colors } from 'constants/colors';
import { mediaSmDown, mediaSmUp } from 'theme/helpers/css';
import { EnumTheme } from '../../constants/enums';

export const GUIDE_CART = 'GUIDE_CART';

const Container = styled(Flex)`
  ${mediaSmDown`
    flex-direction: column;
    background-color: ${colors.white};
    min-height: 100%;
  `}

  ${mediaSmUp`
    width: 600px;
    height: 350px;
    border-radius: 4px;
    margin-left: 27px;
  `}
`;

const ImageContainer = styled.div`
  background-color: ${colors.paleGrey};
  flex-basis: 229px;
  flex-shrink: 0;
  position: relative;
  border-radius: 4px;

  ${mediaSmUp`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    
  `}

  ${mediaSmDown`
    padding: 10px 16px 0;
    overflow: hidden;
    margin: 48px 16px 0;
  `}
`;

const Image = styled.img`
  display: block;

  ${mediaSmDown`
      height: 208px;
      margin: 0 auto;
  `}

  ${mediaSmUp`
    position: absolute;
    bottom: 2px;
    right: 10px;
  `}
`;

const DescriptionContainer = styled(Flex)`
  background-color: ${colors.white};
  padding: 40px;

  ${mediaSmUp`
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  `}

  ${mediaSmDown`
    padding: 20px 16px 16px;
  `}
`;

const NoteTitle = styled(Box)`
  ${mediaSmUp`
      width: 200px;
  `}

  ${mediaSmDown`
    margin-bottom: 8px;
    text-align: center;
  `}
`;

const NoteText = styled(Text)`
  margin-bottom: 16px;

  ${mediaSmDown`
    text-align: center;
  `}
`;

const Button = styled(ThemeButton)`
  margin-top: auto;
`;

const CartGuideModal: React.FC = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const closeModal = () => setIsShowModal(false);

  useEffect(() => {
    if (!window.localStorage.getItem(GUIDE_CART)) {
      setIsShowModal(true);
      window.localStorage.setItem(GUIDE_CART, GUIDE_CART);
    }
  }, []);

  return (
    <CenteredClosableModal show={isShowModal} closeModal={closeModal}>
      <Container data-testid="cart-guide">
        <ImageContainer>
          <Image src={cartNote} alt="" />
        </ImageContainer>
        <DescriptionContainer column grow>
          <>
            <NoteTitle mb={5}>
              <Title level="2">Примечание к товару</Title>
            </NoteTitle>
            <NoteText color={colors.dark300}>
              К&nbsp;каждому товару можно добавить примечание для закупщика. Например, желаемый внешний вид или
              возможный товар на&nbsp;замену
            </NoteText>
            <Button use={EnumTheme.roundRed} size="large" onClick={closeModal} testId="cart-guide-note">
              Ясно
            </Button>
          </>
        </DescriptionContainer>
      </Container>
    </CenteredClosableModal>
  );
};

export default CartGuideModal;
