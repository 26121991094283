import React, { FC } from 'react';
import Icon, { IIcon } from './Icon';

const QuestionMarkIcon: FC<IIcon> = props => (
  <Icon width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.00559 7.99966H4.53037V7.54536C4.53037 6.77827 4.80291 6.38355 5.75314 5.81754C6.72546 5.22918 7.30001 4.4174 7.30001 3.27048V3.25559C7.30001 1.66926 5.98885 0.5 4.04421 0.5C1.90805 0.5 0.766307 1.78097 0.700012 3.46412V3.47901L2.21742 3.47156H2.23216C2.29108 2.48849 2.94666 1.87035 3.96318 1.87035C4.97233 1.87035 5.62791 2.48849 5.62791 3.32261V3.33751C5.62791 4.09716 5.31117 4.52911 4.41988 5.07278C3.41073 5.68348 2.9614 6.35376 2.99823 7.40386L3.00559 7.99966ZM3.86006 11.5C4.53774 11.5 4.99443 11.0531 4.99443 10.3903C4.99443 9.72004 4.53774 9.27319 3.86006 9.27319C3.18238 9.27319 2.71832 9.72004 2.71832 10.3903C2.71832 11.0531 3.18238 11.5 3.86006 11.5Z"
      fill={props.color || 'currentColor'}
    />
  </Icon>
);

export default QuestionMarkIcon;
