import React, { useState, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EMPTY_STRING } from 'constants/constants';
import { EnumNotification, EnumTheme } from 'constants/enums';
import Input, { EnumInputErrorTypes } from 'Components/Input/Input';
import NotificationAction from 'Modules/Notifications/actions';
import ThemeButton from 'Components/ThemedButton';
import { ICity } from 'Modules/SearchAddress/types';
import { IPlace } from 'Modules/Places/types';
import SearchAddressHttp from 'Modules/SearchAddress/http';
import styled from 'styled-components';
import { mediaSmDown, mediaSmUp } from 'theme/helpers/css';
import { Box } from '@igooods/ui-kit';
import { EnumInputSize, EnumInputTheme } from 'constants/enums';
import { Field, Formik } from 'formik';
import { object, string } from 'yup';
import { withSpaceTrim } from 'Components/Input/hocs';
import { FontSize } from 'theme';
import { getTypographyFontStyle } from 'theme/selectors';
import { SATitle } from 'Containers/SelectAddress/Search/Desktop';
import { fieldValidation, getEmailError } from 'Components/Input/utils';
import { Spring, animated } from 'react-spring';
import SuccessedEmail from 'Containers/SelectAddress/SelectShop/SuccessedEmail';

const SUCCESS_MESSAGE = 'Заявка успешно отправлена';
const ERROR_MESSAGE = 'Произошла ошибка';
export const NOT_FOUND_SHOPS_TEST_ID = 'NOT_FOUND_SHOPS_TEST_ID';
export const NOT_FOUND_SHOPS_BTN_TEST_ID = 'NOT_FOUND_SHOPS_BTN_TEST_ID';
export const NOT_FOUND_SHOPS_INPUT_TEST_ID = 'NOT_FOUND_SHOPS_INPUT_TEST_ID';

enum FormDataFields {
  email = 'email',
}

const EmailInput = withSpaceTrim(Input);

const DisplayingErrorMessagesSchema = object().shape({
  [FormDataFields.email]: string()
    .matches(fieldValidation.latin.regex, fieldValidation.latin.errText)
    .matches(fieldValidation.email.regex, fieldValidation.email.errText)
    .required(fieldValidation.email.emptyErrText),
});

const Container = styled(animated.div)`
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1 1 auto;
  margin-bottom: 60px;

  ${mediaSmDown`
    margin-bottom: 0;
  `}
`;

const Subtitle = styled(Box).attrs({
  mt: { xs: 2, sm: 5 },
})`
  text-align: center;
  ${getTypographyFontStyle(FontSize.m)}

  ${mediaSmUp`
    max-width: 600px;
  `}
`;

const InputContainer = styled(Box).attrs({
  width: '360px',
  mr: { sm: 4 },
  mb: { xs: 5, sm: 0 },
})`
  ${mediaSmDown`
    width: 100%;
  `}
`;

const Form = styled.form`
  display: flex;
  margin: 44px auto 0;
  justify-content: center;

  ${mediaSmDown`
    flex-direction: column;
    width: 100%;
  `}
`;

const SubmitBtn = styled(ThemeButton).attrs({
  use: EnumTheme.roundRed,
  size: EnumInputSize.large,
})`
  ${mediaSmDown`
    width: 100%;
  `}
`;

const NotFoundShops: FC = () => {
  const dispatch = useDispatch();
  const { id: city_id, name: city } = useSelector((state: { currentCity: ICity }) => state.currentCity);
  const { street, building, coordinates } = useSelector((state: { currentAddress: IPlace }) => state.currentAddress);
  const [isSussesEnterEmail, setIsSussesEnterEmail] = useState(false);

  const handleSubmit = ({ email }: { email: string }) => {
    const postData = {
      city_id,
      city,
      street,
      building,
      coordinates,
      contact: email,
    };

    (async () => {
      try {
        await SearchAddressHttp.feedbackNotFoundAddress(postData);
        setIsSussesEnterEmail(true);
        dispatch(NotificationAction.setNotificationAction(SUCCESS_MESSAGE, EnumNotification.success));
      } catch {
        dispatch(NotificationAction.setNotificationAction(ERROR_MESSAGE, EnumNotification.danger));
      }
    })();
  };

  if (isSussesEnterEmail) {
    return <SuccessedEmail />;
  }

  return (
    <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
      {styles => (
        <Container data-testid={NOT_FOUND_SHOPS_TEST_ID} style={styles}>
          <SATitle center>Пока не&nbsp;доставляем сюда</SATitle>
          <Subtitle>
            Сообщим о&nbsp;расширении зоны доставки и&nbsp;подарим промокод, если&nbsp;оставите почту.
          </Subtitle>
          <Formik
            initialValues={{
              email: EMPTY_STRING,
            }}
            onSubmit={handleSubmit}
            validationSchema={DisplayingErrorMessagesSchema}
          >
            {({ handleSubmit, errors, touched, isSubmitting, isValid }) => (
              <Form onSubmit={handleSubmit}>
                <InputContainer>
                  <Field
                    testId={NOT_FOUND_SHOPS_INPUT_TEST_ID}
                    name={FormDataFields.email}
                    size={EnumInputSize.large}
                    use={EnumInputTheme.round}
                    as={EmailInput}
                    placeholder="Почта"
                    error={getEmailError(errors[FormDataFields.email], touched[FormDataFields.email])}
                    type="text"
                    inputMode="email"
                    autoFocus
                    errorType={EnumInputErrorTypes.tooltip}
                  />
                </InputContainer>
                <SubmitBtn
                  testId={NOT_FOUND_SHOPS_BTN_TEST_ID}
                  type="submit"
                  disabled={!isValid}
                  isLoading={isSubmitting}
                >
                  Оставить заявку
                </SubmitBtn>
              </Form>
            )}
          </Formik>
        </Container>
      )}
    </Spring>
  );
};

export default NotFoundShops;
