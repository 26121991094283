import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Box, Flex } from '@igooods/ui-kit';
import { NBSP } from 'constants/constants';
import { colors } from 'constants/colors';
import { EnumButtonSize, EnumTheme } from 'constants/enums';
import { URL } from 'constants/urlMaps';
import { mediaSmDown, mediaSmUp } from 'theme/helpers/css';
import { FontFamily, FontSize, FontWeight } from 'theme';
import { IModelLimit } from 'Modules/Cart/types';
import { Text, Title } from 'Components/Typography';
import ArrowRightIcon from 'Components/Icon/ArrowRightIcon';
import ThemedButton from 'Components/ThemedButton';
import ChevronDownMiniIcon from 'Components/Icon/ChevronDownMiniIcon';
import Popup from '../SimplePopup';

interface ILimitsContainerProps {
  isCollapsed: boolean;
}

interface IChevronIconProps {
  isCollapsed: boolean;
}

const Content = styled(Flex).attrs({
  column: true,
  middle: true,
  center: true,
  px: { sm: 13 },
  pb: { xs: 5, sm: 12 },
  pt: { sm: 12 },
  mx: true,
})`
  ${mediaSmUp`
    width: 570px;
  `}
  ${mediaSmDown`
    align-items: start;
  `}
`;

const PopupTitle = styled(Title).attrs({ center: true, fontFamily: FontFamily.secondFont, size: FontSize.xl })`
  line-height: 30px;
  ${mediaSmDown`
    text-align: left;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.5px;
  `}
`;

const DescriptionText = styled(Text).attrs({ center: true, size: FontSize.m })`
  line-height: 21px;
  ${mediaSmDown`
    text-align: left;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.2px;
  `}
`;

const LimitsTitle = styled(Text).attrs({ center: true, fontWeight: FontWeight.semiBold, size: FontSize.m })`
  ${mediaSmDown`
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.2px;
  `}
`;

const LimitsContainer = styled(Flex).attrs({ column: true, fluid: true, mb: 0.25 })<ILimitsContainerProps>`
  ${mediaSmUp`
    will-change: max-height;
    max-height: ${({ isCollapsed }: ILimitsContainerProps) => (isCollapsed ? 245 : 1000)}px;
    overflow-y: hidden;
    width: 466px;
    transition: max-height 0.3s linear;
  `}
`;

const Limit = styled(Flex).attrs({ fluid: true, middle: true, between: true })`
  min-height: 35px;
  height: 35px;
`;

const LimitText = styled(Text).attrs({ size: FontSize.m })`
  ${mediaSmDown`
    font-size: 14px;
    line-height: 22px;
  `}
`;

const ShowMoreBtn = styled(ThemedButton).attrs({ use: EnumTheme.textMain })`
  padding-right: 0;
  padding-left: 0;
`;

const ChevronIcon = styled(ChevronDownMiniIcon)<IChevronIconProps>`
  transform: rotate(${({ isCollapsed }: IChevronIconProps) => (isCollapsed ? 0 : 180)}deg);
`;

const CartButton = styled(ThemedButton).attrs({ use: EnumTheme.roundRed, size: EnumButtonSize.normal })`
  width: 100%;
  height: 48px;
  ${mediaSmUp`
    width: 248px;
  `}
`;

enum Units {
  piece = 'шт',
  gram = 'кг',
}

export interface ILimitsWithProductCount extends IModelLimit {
  productCount: number;
}

interface ILimitsPopupProps {
  show: boolean;
  withCartRedirectButton?: boolean;
  limits: ILimitsWithProductCount[];
  close: () => void;
}

const LimitsPopup: React.FC<ILimitsPopupProps> = ({ show, withCartRedirectButton = false, limits, close }) => {
  const history = useHistory();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const redirect = () => {
    close();
    history.push(URL.cart);
  };

  const getProductAmountString = (limit: number, unit: 'gram' | 'piece'): string =>
    `${Units[unit] === Units.piece ? limit : limit / 1000} ${Units[unit]}`;

  return (
    <Popup show={show} close={close}>
      <Content>
        <Box pt={{ xs: 0.5, sm: 2.5 }} pr={{ xs: 2, sm: 0 }} px={{ sm: 16 }}>
          <PopupTitle>
            Эти{NBSP}товары не{NBSP}купить про{NBSP}запас
          </PopupTitle>
        </Box>
        <Box mt={2.75} pr={{ xs: 4, sm: 0, md: 0 }}>
          <DescriptionText>
            Это нужно, чтобы магазины успевали заполнять полки. Правила меняются каждый день и зависят от магазинов
          </DescriptionText>
        </Box>
        <Box mt={{ xs: 6.5, sm: 6 }} mb={2.5}>
          <LimitsTitle>Сколько получится купить</LimitsTitle>
        </Box>
        <LimitsContainer isCollapsed={isCollapsed}>
          {limits.map(({ id, name, limit, unit, productCount }) => (
            <Limit key={id}>
              <LimitText>{name}</LimitText>
              <Flex>
                <LimitText color={colors.brinkPink}>{getProductAmountString(productCount, unit)}</LimitText>
                <Box mx={1.25} mt={{ xs: 1, sm: 1.5 }}>
                  <ArrowRightIcon color={colors.blackRussian} />
                </Box>
                <LimitText>{getProductAmountString(limit, unit)}</LimitText>
              </Flex>
            </Limit>
          ))}
        </LimitsContainer>
        {limits.length > 7 && (
          <Box mr mb={-2} hideBefore="sm">
            <ShowMoreBtn onClick={() => setIsCollapsed(curr => !curr)}>
              <Flex middle>
                <Text color={colors.pineGreen} size={FontSize.m}>
                  {isCollapsed ? 'Еще товары' : 'Скрыть'}
                </Text>
                <Box mt={1} ml={0.25}>
                  <ChevronIcon isCollapsed={isCollapsed} />
                </Box>
              </Flex>
            </ShowMoreBtn>
          </Box>
        )}
        {withCartRedirectButton && (
          <Flex mt={{ xs: 4.5, sm: 10 }} fluid center>
            <CartButton onClick={redirect}>Обратно в корзину</CartButton>
          </Flex>
        )}
      </Content>
    </Popup>
  );
};

export default LimitsPopup;
