import { useCallback, useEffect, useState } from 'react';
import { statesSS } from './constants';
import { IUseDataApiState } from 'utils/hooks';
import { IShopsForPlaceResponse } from 'Modules/Shops/http';

const useSelectShopState = ({
  isError,
  isSuccess,
  data: { list: shops },
}: IUseDataApiState<IShopsForPlaceResponse>): statesSS => {
  const checkStateForSelectShop = useCallback(() => {
    if (isError || (isSuccess && shops?.length === 0)) return statesSS.empty;
    if (isSuccess) return statesSS.success;
    return statesSS.loading;
  }, [isSuccess, isError, shops]);

  const [stateSelectShop, setStateSelectShop] = useState(checkStateForSelectShop());

  useEffect(() => setStateSelectShop(checkStateForSelectShop()), [checkStateForSelectShop]);

  return stateSelectShop;
};

export default useSelectShopState;
