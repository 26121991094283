import { IGeoGrinderPlace } from '../../Modules/GeoGrinder/types';
import { IPlace } from '../../Modules/Places/types';

export const ADDRESS_SEARCH_VISITED = 'addressSearchVisited';

export const isFirstVisitSearchAddress = (): boolean => {
  if (!localStorage.getItem(ADDRESS_SEARCH_VISITED)) {
    window.localStorage.setItem(ADDRESS_SEARCH_VISITED, ADDRESS_SEARCH_VISITED);
    return true;
  }
  return false;
};

const addressRemoveCountry = (address: string) => {
  return address
    .split(',')
    .filter(part => part !== 'Россия')
    .join(', ');
};

export const getCuttedQuery = (suggestion: IGeoGrinderPlace) => {
  const resultSuggestion = addressRemoveCountry(suggestion.full_address);
  return resultSuggestion;
};

export const getCuttedPlaceFromMap = (placeFromMap: IPlace) => {
  const resultPlaceFromMap = addressRemoveCountry(placeFromMap.name);
  return resultPlaceFromMap;
};

export const cuttedAddressName = (currentAddressName: string) => {
  if (currentAddressName) {
    const resultAddressName = addressRemoveCountry(currentAddressName);
    return resultAddressName;
  }
};
