import React, { FC, ReactChild, ReactNode } from 'react';
import Text from 'Components/Typography/Text';
import { FontSize } from 'theme';
import { colors } from 'constants/colors';
import { Flex } from '@igooods/ui-kit';
import styled from 'styled-components';
import leftRound from './icons/left.svg';
import rightRound from './icons/right.svg';
import { addStyleIfPropTruly } from 'utils/styledComponents';

export const highlightStyles = `
  display: inline;
  box-decoration-break: clone;
  background-repeat: no-repeat;
  background-image: linear-gradient(to top, ${colors.informationLinkLight} 6px, transparent 0), url(${leftRound}), url(${rightRound});
  background-position-x: 2px,0,100%;
  background-position-y: 90%;
  background-size: calc(100% - 5px) 7px,4px 7px,5px 7px;
  color: ${colors.informationLink}
`;

export const Highlight = styled.span`
  padding: 0 2px;
`;

export const HighlightWrapper = styled(Text).attrs({ color: colors.dark300 })``;

export const FlexStyled = styled(Flex).attrs({ column: true, mb: 2.75 })<{ $hovered?: boolean }>`
  cursor: pointer;

  ${addStyleIfPropTruly(
    '$hovered',
    `
      ${Highlight} {
        ${highlightStyles}
      }
    `
  )}

  &:hover {
    ${Highlight} {
      ${highlightStyles}
    }
  }
`;

const HighlightText: FC<{
  hovered?: boolean;
  onClick?: () => void;
  className?: string;
  testId?: string;
  title: string;
  size?: FontSize;
  subtitle?: ReactChild | ReactNode;
}> = ({ testId, size = FontSize.xs, className, hovered, onClick, title, subtitle }) => (
  <FlexStyled $hovered={hovered} className={className} onClick={onClick} data-testid={testId}>
    <HighlightWrapper size={size}>
      <Highlight>{title}</Highlight>
    </HighlightWrapper>
    {subtitle && subtitle}
  </FlexStyled>
);

export default HighlightText;
