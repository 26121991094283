import React from 'react';
import { CartHeaderContent, CartTitle } from './styled';
import { Box, Flex } from '@igooods/ui-kit';
import ClearCartModal from './ClearCartModal';
import { useHistory } from 'react-router';
import ArrowLeftIconNew from 'Components/Icon/ArrowLeftIconNew';

const CartControls: React.FC<{ handleClearCart?: () => void }> = ({ handleClearCart }) => {
  const history = useHistory();
  const goToCatalog = () => history.push('/catalog');

  return (
    <CartHeaderContent>
      <Flex middle>
        <ArrowLeftIconNew width="16" height="16" onClick={goToCatalog} />
        <CartTitle>Корзина</CartTitle>
      </Flex>
      {handleClearCart && (
        <Box mt={{ xs: 0, lg: 4 }}>
          <ClearCartModal onApprove={handleClearCart} />
        </Box>
      )}
    </CartHeaderContent>
  );
};

export default CartControls;
