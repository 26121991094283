import styled from 'styled-components';
import { FontFamily, FontWeight, Transitions } from 'theme';
import { mediaMdDown, mediaSmDown, mediaSmUp } from 'theme/helpers/css';
import { colors } from 'constants/colors';
import { addStyleIfPropTruly } from 'utils/styledComponents';
import Box from 'Components/Grid/Box';
import Flex from 'Components/Grid/Flex';
import ChevronRoundedIcon from 'Components/Icon/ChevronRoundedIcon';
import Text from 'Components/Typography/Text';
import Title from 'Components/Typography/Title';
import CircleCheckIcon from 'Components/Icon/CircleCheckIcon';
import { CHANGE_SHOP_BTN_HEIGHT, SINGLE_SHOP_HEIGHT, SHOP_LIST_PADDING_BOTTOM } from './constants';

export const ChangeShopButton = styled(Flex).attrs({
  pl: 2,
  pxXs: 4.5,
  pb: 0.5,
  grow: true,
  mb: { xs: 3, md: 1 },
})`
  align-items: top;
  min-height: ${CHANGE_SHOP_BTN_HEIGHT}px;
  cursor: pointer;
  position: relative;
  width: 100%;
`;

export const ShopLogoImage = styled(Box).attrs({ mr: 2.25, mt: 0.75 })<{ background: string }>`
  background: url(${({ background }) => background});
  background-size: cover;
  height: 24px;
  width: 24px;
  position: relative;
  flex: 0 0 24px;

  ${mediaSmDown`
    position: absolute;
    left: 0;
  `}
`;

export const ShopLogoImageLoading = styled(Box).attrs({ mr: 2.25, mt: 0.75 })`
  background: ${colors.paleGrey};
  background-size: cover;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  position: relative;

  ${mediaSmDown`
    position: absolute;
    left: 0;
  `}
`;

export const DropdownContainer = styled(Flex).attrs({ column: true, pl: { xs: 2, md: 2 }, ml: { xs: -2, md: 0 } })<{
  isCollapsed: boolean;
  shopsLength: number;
}>`
  width: 90%;
  z-index: 0 !important;
  background-color: ${colors.white};
  height: ${({ shopsLength }) =>
    `${CHANGE_SHOP_BTN_HEIGHT + SINGLE_SHOP_HEIGHT * shopsLength + SHOP_LIST_PADDING_BOTTOM}px`};
  transition: height 150ms ease-out;
  will-change: height;
  ${addStyleIfPropTruly('isCollapsed', 'height: 0;')}
  ${addStyleIfPropTruly('isCollapsed', 'overflow: hidden;')}
`;

export const ChangeShopTextLoading = styled(Box).attrs({ mt: 0.5 })`
  height: 24px;
  width: 75%;
  background-color: ${colors.paleGrey};
  border-radius: 12px;
  ${mediaSmDown`
    position: relative;
    left: 20px;
  `}
`;

export const ChangeShopText = styled(Title).attrs({ color: colors.blackRussian, fontWeight: FontWeight.bold })`
  font-size: 18px;
  line-height: 28px;
  white-space: nowrap;
  ${mediaSmDown`
    position: relative;
    left: 20px;
  `}
`;

export const ChangeShopChevron = styled(ChevronRoundedIcon)<{ isExpand: boolean }>`
  opacity: 1;
  margin: 12px 6px 0;
  transform: rotate(0deg);
  transition: ${Transitions.transform};
  will-change: transform;
  ${addStyleIfPropTruly('isExpand', 'transform: rotate(-180deg);')}
  ${mediaSmDown`
    position: relative;
    left: 18px;
  `}
`;

export const DeliveryDateText = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.2px;
  &::first-letter {
    text-transform: capitalize;
  }
`;

export const PaymentTypesText = styled(Text)`
  max-width: 205px;
  margin-top: 8px;
  letter-spacing: -0.2px;
  line-height: 16px;
  color: ${colors.dark100};
  font-family: ${FontFamily.mainFont};

  ${mediaSmDown`
     max-width: 259px;
     margin-top: 7px;
  `}
`;

export const DeliveryPriceText = styled(Text)`
  font-size: 12px;
  letter-spacing: -0.2px;
  opacity: 0.5;
  margin-top: 3px;
`;

export const ShopName = styled(Title).attrs({ fontWeight: FontWeight.semiBold })`
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.2px;
  position: relative;
  z-index: 1;
`;

export const ShopItem = styled(Flex).attrs({
  column: true,
  pt: { xs: 4, sm: 2.75, md: 3.5 },
  pb: { xs: 4, sm: 2.75, md: 3.75 },
})<{ isDisabled: boolean }>`
  cursor: pointer;
  width: 100%;
  ${addStyleIfPropTruly('isDisabled', 'filter: grayscale(1); opacity: 0.4;')}
  ${mediaSmUp`
    &:hover {
      ${ShopName}::before {
        content: '';
        width: calc(100% + 6px);
        height: 7px;
        border-radius: 3.5px;
        position: absolute;
        bottom: 2px;
        left: -3px;
        background-color: ${colors.sweetCorn};
        z-index: -1;
      }
    }
  `}
`;

export const ShopItemLogo = styled(Box).attrs({ mr: -1.75 })<{ $src: string }>`
  height: 24px;
  width: 24px;
  margin-right: 7px;
  position: relative;
  background-image: url(${({ $src }) => $src});
  background-size: cover;
`;

export const ShopNameContainer = styled(Flex).attrs({ middle: true })`
  height: 24px;
  position: relative;
`;

export const ShopCheckIcon = styled(CircleCheckIcon)`
  width: 11.5px;
  height: 11.5px;
  position: absolute;
  top: -2px;
  left: -2.5px;
  z-index: 1;
`;

export const Address = styled(Text)`
  display: inline-block;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.2px;
  margin-top: 7px;
  margin-bottom: 16px;
  cursor: pointer;
  & svg {
    margin-bottom: -2px;
    margin-right: 4px;
  }
  ${mediaMdDown`
    margin-top: 18px;
    margin-bottom: 23px;
  `}
  ${mediaSmDown`
    margin-top: 18px;
    margin-bottom: 18px;
  `}
`;
