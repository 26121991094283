import React from 'react';
import { SearchAddressFromType, SearchAddressSizes } from './constants';
import SearchAddressContent from './SearchAddressContent';
import { EnumInputTheme } from 'constants/enums';

export const ERROR_EMPTY = 'Введите свой адрес';
export const ERROR_NOT_FULL = 'Введите полный адрес';
export const ERROR_NOT_FOUND = 'Адрес не найден';

export interface ISearchAddressProps {
  size?: SearchAddressSizes;
  from?: SearchAddressFromType;
  onSelectAddress: () => void;
  autoFocus?: boolean;
  mapBtnTestId?: string;
  inputTestId?: string;
  use?: EnumInputTheme;
  hideButton?: boolean;
}

const SearchAddressDesktop: React.FC<ISearchAddressProps> = ({
  size = SearchAddressSizes.big,
  from = SearchAddressFromType.select_address,
  autoFocus,
  onSelectAddress,
  use,
  hideButton,
}) => {
  return <SearchAddressContent {...{ size, from, autoFocus, onSelectAddress, use, hideButton }} />;
};

export default SearchAddressDesktop;
