import React, { memo, useCallback, useEffect, useState } from 'react';
import { equals } from 'ramda';
import { useDataApi } from 'utils/hooks';
import { IPlace } from 'Modules/Places/types';
import Cookie from 'js-cookie';
import http, { IShopsForPlaceResponse } from 'Modules/Shops/http';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IShopForPlace } from 'Modules/Shops/types';
import {
  EnumSelectShopFrom,
  initDataShopsForPlace,
  SELECT_SHOP_LOADING_CONTAINER_TEST_ID,
  statesSS,
} from './constants';
import CurrentShopActions from 'Modules/Shops/actions';
import { getCartState } from 'Modules/Cart/selectors';
import OrderActions from 'Modules/Cart/actions';
import { URL } from 'constants/urlMaps';
import { getCurrentAddress, getCurrentCityId } from 'Modules/SearchAddress/selectors';
import useSelectShopState from './useSelectShopState';
import { Box } from '@igooods/ui-kit';
import Loader from 'Components/Loader';
import NotFoundShops from '../SelectAddress/SelectShop/outOfZoneAddress';
import { sortShopsByDeliveryTime } from './utils';
import { IAddressWithCoordinate } from 'Modules/SearchAddress/types';
import SelectShop from 'Containers/SelectShop/SelectShop';
import HeaderDropdown from 'Components/Header/HeaderDropdown';
import { viewShopsEvent } from 'utils/tagManager/handlers/checkout';
import analyticsManager from 'utils/tagManager/manager';
import { EnumGtagEvents } from 'constants/enums';
import NotificationAction from 'Modules/Notifications/actions';
import { CLOSED_SHOP_ERR_NOTIFICATION_TEXT } from 'Containers/Cart/Full';
import { EnumNotification } from 'constants/enums';
import styled from 'styled-components';
import { DYNAMIC_PRICES_AB_TEST_NAME } from 'constants/constants';
import { checkIsTempUser } from 'Modules/AAA/selectors';
import { setUserTest, removeUserTest } from 'Modules/AAA/actions';
import { getCartId } from 'Modules/Cart/selectors';
import { truncateObjValues } from 'utils/helpers';
import { setGtagUserProperties } from 'utils/gtag';
import { getTestParticipationParam } from 'utils/helpers';
import { addAddressToHistory } from 'Components/SearchAddress_new/SearchHistory/utils';
import { AddressComponentsEnum } from 'Modules/GeoGrinder/types';
import { IS_KOMUS } from '../../../config/config';

export const SELECT_SHOP_DROPDOWN_TEST_ID = 'SELECT_SHOP_DROPDOWN_TEST_ID';

export interface ISelectShopLocationState {
  redirect?: string;
  prevPath?: string;
}

export type IOnShopChanged = (shop: IShopForPlace, place?: IPlace) => void;

export type IOnSelectShop = (shop: IShopForPlace, onShopChanged: IOnShopChanged) => void;

const CHARS_LIMIT = 36;

const LoadingBox = styled(Box)`
  align-self: center;
`;
interface ISelectShopDropdown {
  address?: IPlace;
  onClickEditPlace?: () => void;
  from: EnumSelectShopFrom;
  onSelectShop: IOnSelectShop;
  onShopsLoad?: (data: any) => void;
  userInputAddress?: string;
  fullHeight?: boolean;
  onHide: () => void;
  withCloseBtn?: boolean;
  withEmptyShopsListNotification?: boolean;
}

const Loading = () => {
  return (
    <LoadingBox width="100%" pt={11} pb={50} data-testid={SELECT_SHOP_LOADING_CONTAINER_TEST_ID}>
      <Loader />
    </LoadingBox>
  );
};

const SelectShopDropdown: React.FC<ISelectShopDropdown> = ({
  address,
  onClickEditPlace,
  from,
  onSelectShop,
  onShopsLoad,
  fullHeight,
  onHide,
  withCloseBtn,
  withEmptyShopsListNotification = false,
}) => {
  const history = useHistory();
  const { state: locationState } = useLocation<ISelectShopLocationState | undefined>();
  const dispatch = useDispatch();
  const currentOrder = useSelector(getCartState);
  const currentAddress = useSelector(getCurrentAddress);
  const addressForSearch: IPlace = address || currentAddress;
  const cityId = useSelector(getCurrentCityId);
  const [stateShopsForPlace, fetchDataShopsForPlace] = useDataApi<IShopsForPlaceResponse>(initDataShopsForPlace);
  const {
    isSuccess,
    data: { list: shops },
  } = stateShopsForPlace;
  const sortedShops = sortShopsByDeliveryTime(shops);
  const stateSelectShop = useSelectShopState(stateShopsForPlace);
  const { latitude, longitude } = addressForSearch.coordinates;
  const [isChangeShopLoading, setIsChangeShopLoading] = useState(false);
  const isTempUser = useSelector(checkIsTempUser);
  const cartId = useSelector(getCartId);

  useEffect(() => {
    if (latitude && longitude) {
      if (!cartId && !IS_KOMUS) {
        (async () => {
          try {
            const abTest = await http.getUserABTest(DYNAMIC_PRICES_AB_TEST_NAME, cityId);
            if (abTest) {
              const userPriceTestParticipationParam = getTestParticipationParam(abTest.participation);
              dispatch(setUserTest(DYNAMIC_PRICES_AB_TEST_NAME, abTest));
              userPriceTestParticipationParam && setGtagUserProperties({ ...userPriceTestParticipationParam });
            }
          } catch (e) {
            dispatch(removeUserTest(DYNAMIC_PRICES_AB_TEST_NAME));
            setGtagUserProperties({ [DYNAMIC_PRICES_AB_TEST_NAME]: 'undefined' });
          }
        })();
      }
      fetchDataShopsForPlace(http.getShopsForPlace({ latitude, longitude }));
    }
  }, [cartId, cityId, dispatch, fetchDataShopsForPlace, isTempUser, latitude, longitude]);

  useEffect(() => {
    if (isSuccess) {
      viewShopsEvent(cityId, shops.length > 0 ? shops.map(shop => shop.id) : 0);
    }
  }, [cityId, isSuccess, shops]);

  useEffect(() => {
    if (stateSelectShop === statesSS.empty && withEmptyShopsListNotification) {
      history.push(URL.select_address);
      dispatch(NotificationAction.setNotificationAction(CLOSED_SHOP_ERR_NOTIFICATION_TEXT, EnumNotification.danger));
    }
  }, [dispatch, history, stateSelectShop, withEmptyShopsListNotification]);

  useEffect(() => {
    if (from !== EnumSelectShopFrom.SA) return;

    if (stateSelectShop === statesSS.success && addressForSearch.name) {
      addAddressToHistory(cityId, addressForSearch as IAddressWithCoordinate);
    }
  }, [stateSelectShop, currentOrder.id, cityId, from, addressForSearch]);

  useEffect(() => {
    console.log('srabotalo');
    if (isSuccess && onShopsLoad) {
      console.log('inside');
      console.log(stateShopsForPlace);
      onShopsLoad(stateShopsForPlace);
    }
  }, [isSuccess, onShopsLoad, stateShopsForPlace]);

  const handlerSelectShop = useCallback(
    (shop: IShopForPlace) => {
      setIsChangeShopLoading(true);

      const onShopChanged = async (shop: IShopForPlace, place?: IPlace) => {
        await dispatch(CurrentShopActions.setCurrentShop(shop));
        await dispatch(OrderActions.createOrder(shop.id, place || stateShopsForPlace.data.place, shop.branding));

        const urlProductFrom = Cookie.get('productFromUrl');
        if (!urlProductFrom) {
          //TODO когда сделаем нормалную страницу каталога, надо будет вернуть URL.catalog вместо '/catalog/categories/1'
          return history.push(locationState?.prevPath || locationState?.redirect || '/catalog/categories/1');
        } else {
          Cookie.remove('productFromUrl');
          return history.push(urlProductFrom);
        }
      };

      const type = from === EnumSelectShopFrom.SA || from === EnumSelectShopFrom.Main ? 'new_shop' : 'toggle_shop';
      analyticsManager.gtag(
        EnumGtagEvents.SELECT_SHOP,
        truncateObjValues({ type, shop_group: shop.group, shop_id: shop.id }, CHARS_LIMIT)
      );
      onSelectShop(shop, onShopChanged);
    },
    [dispatch, from, history, locationState, onSelectShop, stateShopsForPlace]
  );

  const isNeedCloseBtn =
    (stateSelectShop === statesSS.empty || withCloseBtn) &&
    stateSelectShop !== statesSS.loading &&
    !isChangeShopLoading;

  const getAddressWithoutStreet = () => {
    const addressParts = (address || currentAddress)?.name.split(',').slice(-2);
    return `${addressParts[0]}, ${addressParts[1]}`;
  };

  const addressWithoutStreet = getAddressWithoutStreet();

  const street = (address || currentAddress)?.address_components.find(
    ({ type }) => type === AddressComponentsEnum.STREET
  )?.name;
  const building = (address || currentAddress)?.address_components.find(
    ({ type }) => type === AddressComponentsEnum.BUILDING
  )?.name;

  return (
    <HeaderDropdown
      isOpen={true}
      fullHeight={fullHeight}
      onHide={onHide}
      withCloseBtn={isNeedCloseBtn}
      testId={SELECT_SHOP_DROPDOWN_TEST_ID}
      withHideOnClickOutside={false}
    >
      {
        {
          [statesSS.loading]: <Loading />,
          [statesSS.success]: (
            <SelectShop
              onClickEditPlace={onClickEditPlace}
              address={street && building ? `${street}, ${building}` : addressWithoutStreet || address?.name}
              shops={sortedShops}
              onSelectShop={handlerSelectShop}
              isChangeShopLoading={isChangeShopLoading}
            />
          ),
          [statesSS.empty]: <NotFoundShops />,
        }[stateSelectShop]
      }
    </HeaderDropdown>
  );
};

export default memo(SelectShopDropdown, equals);
