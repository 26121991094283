import { BreakPointsCards, ModeCards } from './constants';
import { isNil, sortWith, ascend, path } from 'ramda';
import { IShopForPlace } from 'Modules/Shops/types';

const MAXIMUM_SHOPS_IN_ROW = 6;

export const getModeCardByWidth = (widthShopCard: number, countShops: number) => {
  if (widthShopCard >= BreakPointsCards.big) {
    return ModeCards.big;
  }
  if (widthShopCard >= BreakPointsCards.middle) {
    return ModeCards.middle;
  }
  const hasMaximumShopsInRow = countShops % MAXIMUM_SHOPS_IN_ROW;
  if (widthShopCard < BreakPointsCards.middle && !hasMaximumShopsInRow) {
    return ModeCards.small;
  }
  return ModeCards.smallest;
};

export const sortShopsByDeliveryTime = (shops: IShopForPlace[], groupPriority?: string): IShopForPlace[] => {
  if (isNil(shops) || !shops.length) return [];

  let prioritizedShop = null;

  const notAvailableShops: IShopForPlace[] = [];

  const availableShops = shops.filter((shop: IShopForPlace) => {
    if (groupPriority && (shop.group === groupPriority || shop.group_en === groupPriority)) {
      prioritizedShop = shop;
      return;
    }

    if (isNil(shop.zone.delivery_date)) {
      notAvailableShops.push(shop);
      return;
    }

    return true;
  });

  const sortedAvailable = sortWith(
    [ascend(path(['zone', 'delivery_date', 'date'])), ascend(path(['zone', 'delivery_date', 'end_date']))],
    availableShops
  );

  if (prioritizedShop) {
    if (isNil(prioritizedShop)) notAvailableShops.unshift(prioritizedShop);
    else sortedAvailable.unshift(prioritizedShop);
  }

  return sortedAvailable.concat(notAvailableShops);
};
