import React, { useState } from 'react';
import { directSortStates } from 'constants/enums';
import { ISortingTab } from './types';
import { StyledSortIcon, Tab, ChipName, ProductChip } from './styled';

export interface ITab {
  type: string;
  title: string;
  onSort: (type: string, direction?: string) => void;
  hasDirection?: boolean;
}

const SortingTab: React.FC<ISortingTab> = ({ title, type, hasDirection, active, onSort, onClick, isCheap }) => {
  const [direction, setDirection] = useState(directSortStates.asc);
  const getNextDirection = () => {
    if (active) {
      if (direction === directSortStates.desc) {
        return directSortStates.asc;
      }

      return directSortStates.desc;
    }

    return direction;
  };

  const onClickHandler = () => {
    const nextDirection = getNextDirection();
    if (onClick) onClick();
    setDirection(nextDirection);
    if (hasDirection) {
      onSort(type, nextDirection);
    } else {
      onSort(type);
    }
  };

  return isCheap ? (
    <ProductChip isChipOnFocus={active} onClick={onClickHandler}>
      <ChipName isChipOnFocus={active}>{title}</ChipName>
    </ProductChip>
  ) : (
    <Tab key={type} active={active} onClick={onClickHandler} data-testid={`filter-sort-${type}`} direction={direction}>
      {title}
      {hasDirection && <StyledSortIcon active={active} direction={direction} />}
    </Tab>
  );
};

export default SortingTab;
