import React from 'react';
import Icon, { IIcon } from './Icon';

const BadgeApprovedIcon: React.FC<IIcon> = props => (
  <Icon width="22" height="22" viewBox="0 0 22 22" fill="none" color="#1BA39A" {...props}>
    <g clipPath="url(#clip0_1_49)">
      <circle cx="11" cy="11" r="10.5" fill="currentColor" stroke="white" />
      <path
        d="M7.16808 7.65H9.13718C9.35481 7.65 9.51967 7.84651 9.48185 8.06082L8.33479 14.5608C8.30528 14.7281 8.15996 14.85 7.99012 14.85H6.02102C5.80339 14.85 5.63853 14.6535 5.67635 14.4392L6.82341 7.93917C6.85292 7.77192 6.99825 7.65 7.16808 7.65Z"
        fill="currentColor"
        stroke="white"
        strokeWidth="1.3"
      />
      <path
        d="M8.52148 14L10.3084 14.7162C10.7795 14.905 11.2822 15.0024 11.7897 15.0032L13.4976 15.0061C14.5001 15.0078 15.349 14.2671 15.483 13.2736L15.8689 10.414C15.9499 9.81405 15.4833 9.2803 14.8779 9.2803H12.9315C12.86 9.2803 12.8112 9.20784 12.8372 9.14116C13.227 8.14031 13.6123 6.31537 12.3756 6.04067C10.7551 5.68068 11.5943 7.80872 10.7551 8.56054C10.5585 8.73664 9.968 8.8142 9.52148 8.87264"
        fill="currentColor"
        stroke="white"
        strokeWidth="1.3"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_49">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

export default BadgeApprovedIcon;
